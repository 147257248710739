import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoArrowBackCircle } from "react-icons/io5";
import { FaSearch, FaShareAlt } from "react-icons/fa";
import { IoSearchCircle } from "react-icons/io5";
import { IoIosArrowForward } from "react-icons/io";
import { SocialIcon } from "react-social-icons";
import { HiOutlineDotsVertical } from "react-icons/hi";

const OtherGroupProfileSection = () => {
    const navigate = useNavigate();
    const menuRef = useRef(null);
    const [showShare, setShowShare] = useState(false);
    const [userPosition, setUserPosition] = useState(0);
    const [socialMediaPosition, setSocialMediaPosition] = useState(0);
    const [showMenu, setShowMenu] = useState(false);

    const userData = [
        {
            id: 1,
            userImg: "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            id: 2,
            userImg: "https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            id: 3,
            userImg: "https://images.unsplash.com/photo-1544005313-94ddf0286df2?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            id: 4,
            userImg: "https://images.unsplash.com/photo-1574701148212-8518049c7b2c?q=80&w=1886&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            id: 5,
            userImg: "https://images.unsplash.com/photo-1622236297949-eb7f7aa3a3cc?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
        {
            id: 6,
            userImg: "https://images.unsplash.com/photo-1578979879663-4ba6a968a50a?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
        },
    ];

    const socialMediaData = [
        {
            id: 1,
            iconname: "telegram",
        },
        {
            id: 2,
            iconname: "whatsapp",
        },
        {
            id: 3,
            iconname: "facebook",
        },
        {
            id: 4,
            iconname: "x",
        },
        {
            id: 5,
            iconname: "snapchat",
        },
        {
            id: 6,
            iconname: "instagram",
        },
    ]

    const handleUserClick = () => {
        setUserPosition(prevPosition => (prevPosition + 1) % (userData.length - 3));
    };

    const handleSocialClick = () => {
        setSocialMediaPosition(prevPosition => (prevPosition + 1) % (socialMediaData.length - 3));
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowShare(null); // Close menu if clicked outside
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className="h-[30%] md:h-[35%] bg-black pt-3 mb-2 md:mb-0 overflow-y-scroll">
            <div className="grid grid-cols-12 px-3 items-center">
                <div className="col-span-2">
                    <IoArrowBackCircle
                        className="text-white text-[30px] md:text-[24px] cursor-pointer"
                        onClick={() => navigate(-1)}
                    />
                </div>
                <div className="col-span-8 text-center">
                    <p className="text-white text-[20px] md:text-[17px]">Ferrari Group</p>
                </div>
                <div className="col-span-2">
                    <div className="flex items-center gap-2 float-end">
                        {showMenu ?
                            <p className="text-white hover:text-red-600 text-[12px] font-bold cursor-pointer" onClick={() => setShowMenu(false)}>Close</p>
                            :
                            <HiOutlineDotsVertical className="text-white text-[30px] md:text-[24px] cursor-pointer" onClick={() => setShowMenu(!showMenu)} />
                        }
                    </div>
                </div>
            </div>

            {showMenu &&
                <div className="absolute top-10 right-2 bg-[#d9d9d9] px-3 py-1 z-50 rounded">
                    <p className="text-black hover:text-green-500 text-[13px] cursor-pointer">Mute Notifications </p>
                    <p className="text-black hover:text-green-500 text-[13px] cursor-pointer">Report Group </p>
                    <p className="text-black hover:text-green-500 text-[13px] cursor-pointer">Leave Group </p>
                </div>
            }

            <hr className="w-full mt-[6px] my-[6px] h-[0.3px] bg-gray-800 border-0" />

            <div className="grid grid-cols-12 px-3 pt-[4px] relative">
                <div className="col-span-3">
                    <img
                        alt="groupprofilepicture"
                        className="h-20 md:h-16 w-20 md:w-16 object-cover rounded-full"
                        src="https://images.unsplash.com/photo-1576020111856-ac48d70c5a04?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    />
                </div>
                <div className="col-span-7 px-5">
                    <div className="flex flex-col gap-2 mt-[5px]">
                        <button className="hover:bg-[#3af800c8] bg-[#3af800] text-white font-bold px-2 py-1 w-[6rem] md:w-[4.3rem] text-[16px] md:text-[12px] rounded">Join</button>
                        <div className="flex gap-[5px] items-center mt-[4px]">
                            <p className="text-[18px] md:text-[15px] text-white font-bold">89</p>
                            <p className="text-[16px] md:text-[14px] text-white">Members</p>
                        </div>
                    </div>
                </div>
                <div className="col-span-2">
                    <div className="flex flex-col gap-[9px] items-end justify-between">
                        <div
                            onClick={() => navigate("/othergroup/search")}
                            className="hover:bg-[#9d00ff] hover:text-white text-black bg-white p-[6px] rounded cursor-pointer">
                            <FaSearch className="text-[20px] md:text-[14px]" />
                        </div>
                        <div
                            onClick={() => setShowShare(!showShare)}
                            className="hover:bg-[#9d00ff] hover:text-white text-black bg-white p-[6px]  mt-[4px] rounded cursor-pointer">
                            <FaShareAlt className="text-[20px] md:text-[14px]" />
                        </div>
                    </div>
                </div>
                <div className="col-span-12">
                    <p className="text-white text-[15px] md:text-[12px] pt-2 line-clamp-3">
                        The Ferrari Group epitomizes the pinnacle of automotive excellence, renowned globally for
                        its unparalleled luxury, performance, and innovation, while continuing to push the boundaries
                        of engineering and design.
                    </p>
                </div>
                {showShare &&
                    <div className="absolute top-0 right-1 bg-white rounded" ref={menuRef}>
                        <div className="flex items-center gap-2 px-1 pt-1">
                            <p className="text-black text-[13px]">Share With : </p>
                            <IoSearchCircle className="text-[24px] cursor-pointer" />
                        </div>

                        <div className="flex gap-1 px-1 relative">
                            {userData.slice(userPosition, userPosition + 4).map((item) => (
                                <div key={item.id}>
                                    <img
                                        alt="img"
                                        src={item.userImg}
                                        className="h-7 w-7 bg-black rounded-full object-cover"
                                    />
                                </div>
                            ))}
                            <p className="h-7 w-7 bg-black text-white hover:bg-[#9d00ff] rounded-full flex justify-center items-center cursor-pointer" onClick={handleUserClick}>
                                <IoIosArrowForward className="text-[18px]" />
                            </p>
                        </div>

                        <hr className="w-full mt-[6px] my-[6px] h-[0.5px] bg-gray-800 border-0" />

                        <div className="flex gap-1 px-1 pb-2">
                            {socialMediaData.slice(socialMediaPosition, socialMediaPosition + 4).map((item) => (
                                <div key={item.id}>
                                    <SocialIcon network={item.iconname} url="#" style={{ height: "28px", width: "28px" }} />
                                </div>
                            ))}
                            <p className="h-7 w-7 bg-black text-white hover:bg-[#9d00ff] rounded-full flex justify-center items-center cursor-pointer" onClick={handleSocialClick}>
                                <IoIosArrowForward className="text-[18px]" />
                            </p>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default OtherGroupProfileSection