import { useState } from "react";
import { IoSearch } from "react-icons/io5";
import { Typography } from "@material-tailwind/react";
export const MessagesConversationTab = ({ iswebVersion }) => {
  const [isChecked1, setIsChecked1] = useState(false);
  const [isChecked2, setIsChecked2] = useState(false);

  const handleCheckbox1Change = () => {
    setIsChecked1(!isChecked1);
    setIsChecked2(false); // Uncheck checkbox 2 when checkbox 1 is checked
  };

  const handleCheckbox2Change = () => {
    setIsChecked2(!isChecked2);
    setIsChecked1(false); // Uncheck checkbox 1 when checkbox 2 is checked
  };

  return (
    <>
      {iswebVersion ? (
        <section className="h-full w-full p-[10rem] relative">
          <p className="text-[12px] text-[red] font-bold absolute top-6  w-full justify-center text-center">
            CC / MESSAGES CONVERSATION V-2/ OCT 11 /2024
          </p>
          <div className=" bg-[#fff] flex flex-col justify-center items-center p-4">
            <p className="text-[12px] md:text-[16px] font-semibold">
              You can manage your chat requests permissions here. You can choose
              to receive direct messages or requests from people not in your
              contact list.{" "}
              <span className="text-[#000] font-bold  bg-[#fff] underline ">
                Learn more.
              </span>
            </p>
            <div className="mt-4 flex flex-col justify-start items-start w-full">
              <p className="text-[#000] text-[11px] flex mt-2  items-center justify-center ">
                <input
                  type="radio"
                  className="h-5 w-5 text-blue-500 bg-red-600 "
                  name="name"
                  onChange={handleCheckbox1Change}
                  checked={isChecked1}
                />
                <div className="ml-4">
                  <Typography className="font-medium text-[16px] ">
                    Receive direct messages
                  </Typography>
                </div>
              </p>
              <p className="text-[#000] text-[16px] flex mt-2 items-center justify-center">
                <input
                  type="radio"
                  className="h-5 w-5 text-blue-500 "
                  name="name"
                  onChange={handleCheckbox2Change}
                  checked={isChecked2}
                />

                <div className="ml-4">
                  <Typography className="font-medium text-[16px] ">
                    Receive chat requests
                  </Typography>
                </div>
              </p>
            </div>
            <div className="flex justify-center items-center mt-6 w-full">
              <button className="bg-[#762978] text-[#fff] text-[16px] p-[0.5rem] w-[20%] rounded-xl font-bold">
                save changes
              </button>
            </div>
          </div>
        </section>
      ) : (
        <div className="h-[75%]">
          <div className="mobileMessages-searchBar-container  mt-[1rem]">
            <div className=" h-[45%] w-[80%] relative">
              {/* <img src={searchIcon} /> */}
              <IoSearch className="absolute top-[25%] ml-1 w-[15px] h-[15px]" />
              <input
                className="w-full h-full rounded-[12px] p-1 bg-fuchsia-50 outline-none pl-5 placeholder:text-[14px] placeholder:font-semibold placeholder:pl-4"
                type="text"
                name=""
                id=""
                width={25}
                height={25}
                placeholder="Find Contacts or Conversations..."
              />
            </div>
          </div>
          <div className=" h-[90%] flex justify-center items-center relative">
            <div className=" bg-[#fff] flex flex-col justify-center items-center p-4">
              <p className="text-[12px] md:text-[11px] font-semibold">
                You can manage your chat requests permissions here. You can
                choose to receive direct messages or requests from people not in
                your contact list.{" "}
                <span className="text-[#000] font-bold  bg-[#fff] underline ">
                  Learn more.
                </span>
              </p>
              <div className="mt-2">
                <div className="text-[#000] text-[11px]  mt-2 flex justify-center items-center">
                  {/* <input
                    type="checkbox"
                    className="h-5 w-5 text-blue-500 "
                    name="name"
                   
                  /> */}

                  <div class="inline-flex items-center">
                    <label
                      class="relative flex items-center p-3 rounded-full cursor-pointer"
                      htmlFor="purple"
                    >
                      <input
                        type="checkbox"
                        class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-purple-500 checked:bg-purple-500 checked:before:bg-purple-500 hover:before:opacity-10"
                        id="purple"
                        onChange={handleCheckbox1Change}
                        checked={isChecked1}
                      />
                      <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-3.5 w-3.5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-width="1"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </label>
                  </div>
                  <Typography className="font-medium text-[11px] ml-1">
                    Receive direct messages
                  </Typography>
                </div>
                <div className="text-[#000] text-[11px] flex items-center mt-2">
                  <div class="inline-flex items-center">
                    <label
                      class="relative flex items-center p-3 rounded-full cursor-pointer"
                      htmlFor="purple"
                    >
                      <input
                        type="checkbox"
                        class="before:content[''] peer relative h-5 w-5 cursor-pointer appearance-none rounded-md border border-blue-gray-200 transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-purple-500 checked:bg-purple-500 checked:before:bg-purple-500 hover:before:opacity-10"
                        id="purple"
                        onChange={handleCheckbox2Change}
                        checked={isChecked2}
                      />
                      <span class="absolute text-white transition-opacity opacity-0 pointer-events-none top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-3.5 w-3.5"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          stroke="currentColor"
                          stroke-width="1"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clip-rule="evenodd"
                          ></path>
                        </svg>
                      </span>
                    </label>
                  </div>

  
                    <Typography className="font-medium text-[11px] ml-1">
                      Receive chat requests
                    </Typography>
               
                </div>
              </div>
              <div className="absolute top-5 w-full flex justify-center flex-col items-center">
                <p className="text-[8px] text-[#fc4343] font-bold">
                  CC / MESSAGES MANAGE CONVERSATION/ V2 / OCT 11, 2024
                </p>
                <p className="text-[8px] text-[#000] font-bold">
                  Copyright © 2024 by Vikas Rai and Sudha Rai and Galileo One
                  Inc{" "}
                </p>
              </div>
              <div className="flex justify-center items-center mt-4 w-full">
                <button className="bg-[#000] text-[#fff] text-[10px] p-[0.3rem] w-[25%] rounded-3xl">
                  save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
