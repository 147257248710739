import React, { useState } from "react";
import { motion } from "framer-motion";
import { LuSunMoon } from "react-icons/lu";
import { RiFontSize } from "react-icons/ri";
import { MdDarkMode } from "react-icons/md";
import { IoIosArrowDropleftCircle } from "react-icons/io";
import { MdDisplaySettings } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function DisplaySetting({ openDisplaySettings }) {
   const navigate = useNavigate();
   const [isChecked1, setIsChecked1] = useState(false);
   const [isChecked2, setIsChecked2] = useState(false);
   const [isChecked3, setIsChecked3] = useState(false);

   const handleCheckbox1 = () => setIsChecked1(!isChecked1);
   const handleCheckbox2 = () => setIsChecked2(!isChecked2);
   const handleCheckbox3 = () => setIsChecked3(!isChecked3);

   return (
      <>
         <motion.div
            initial={{ x: "100%" }}
            animate={{ x: openDisplaySettings ? 0 : "-100%" }}
            transition={{ duration: 0.5 }}
            className="w-full h-full"
         >
            <div className="w-full grid grid-col-12 p-2">
               <div className="col-span-1 py-2">
                  <IoIosArrowDropleftCircle
                     className="text-black hover:text-[#353097] text-[1.5rem] cursor-pointer"
                     onClick={() => navigate(-1)}
                  />
               </div>
               <div className="col-span-11 text-[14px] font-extrabold flex items-center py-2">
                  Display Settings
               </div>

               <div className="col-span-12 flex justify-between items-center py-2 px-2 ">
                  <div className="flex justify-between gap-4 items-center text-center text-[13px] font-bold">
                     {!isChecked2 ? (
                        <LuSunMoon color={"black"} className="text-[1.2rem]" />
                     ) : (
                        <MdDarkMode color={"black"} className="text-[1.2rem]" />
                     )}
                     {isChecked2 ? "Dark Mode" : "Light Mode"}
                  </div>
                  <div>
                     <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                        <input
                           type="checkbox"
                           name="autoSaver"
                           className="sr-only"
                           checked={isChecked2}
                           onChange={handleCheckbox2}
                        />
                        <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked2 ? "bg-[black]" : "bg-[#a6a6a6]"}`}>
                           <span
                              className={`dot h-[13px] w-[13px] rounded-full ${isChecked2
                                 ? "bg-[#ffffff]"
                                 : "bg-[black]"} shadow-2xl duration-200 ${isChecked2 ? "translate-x-5" : ""}`}
                           />
                        </span>
                     </label>
                  </div>
               </div>
               <div className="col-span-12 flex justify-between items-center py-2 px-2 ">
                  <div className="flex justify-between gap-4 items-center text-center text-[13px] font-bold ">
                     <RiFontSize className="text-[1.2rem]" />
                     Increase Font
                  </div>
                  <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                     <input
                        type="checkbox"
                        name="autoSaver"
                        className="sr-only"
                        checked={isChecked1}
                        onChange={handleCheckbox1}
                     />
                     <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked1 ? "bg-[black]" : "bg-[#a6a6a6]"}`}>
                        <span
                           className={`dot h-[13px] w-[13px] rounded-full ${isChecked1
                              ? "bg-[#ffffff]"
                              : "bg-[black]"} shadow-2xl duration-200 ${isChecked1 ? "translate-x-5" : ""}`}
                        />
                     </span>
                  </label>
               </div>
               <div className="col-span-12 flex justify-between items-center py-2 px-2 ">
                  <div className="flex justify-between gap-4 items-center text-center text-[13px] font-bold">
                     <MdDisplaySettings className="text-[1.2rem]" />
                     Use System Settings
                  </div>
                  <div>
                     <label className="autoSaverSwitch relative inline-flex cursor-pointer select-none items-center">
                        <input
                           type="checkbox"
                           name="autoSaver"
                           className="sr-only"
                           checked={isChecked3}
                           onChange={handleCheckbox3}
                        />
                        <span className={`slider flex h-[19px] w-[40px] items-center rounded-full p-1 duration-200 ${isChecked3 ? "bg-[black]" : "bg-[#a6a6a6]"}`}>
                           <span
                              className={`dot h-[13px] w-[13px] rounded-full ${isChecked3
                                 ? "bg-[#ffffff]"
                                 : "bg-[black]"} shadow-2xl duration-200 ${isChecked3 ? "translate-x-5" : ""}`}
                           ></span>
                        </span>
                     </label>
                  </div>
               </div>
            </div>
         </motion.div>
      </>
   );
}

export default DisplaySetting;
