import React, { useEffect, useRef, useState } from "react";
import { PostsTab } from "./PostsTab";
import { BsMailbox2 } from "react-icons/bs";
import { TbPhotoEdit } from "react-icons/tb";
import AccountSidebar from "./AccountSidebar";
import { FaUserGroup } from "react-icons/fa6";
import { FollowersTab } from "./FollowersTab";
import ShareModal from "../Modals/ShareModal";
import { FollowingTab } from "./FollowingTab";
import AccountTabModal from "./AccountTabModal";
import { useNavigate } from "react-router-dom";
import { IoNotifications } from "react-icons/io5";
import { FaShare, FaUserCheck } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import Editicon from "../../Assets/YourAccountIcons/editicon.png";
import { getPublicPrivateToggle } from "../../Redux/ApiSlice/UserStatusSlice";
import {
  Square3Stack3DIcon,
  UserCircleIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/solid";
import AccountNameEditModal from "../../Component/Modals/AccountPageModal/AccountNameEditModal";
import { AccountSettingModal } from "../../Component/Modals/AccountPageModal/AccountSettingModal";
import AccountProfileEditModal from "../../Component/Modals/AccountPageModal/AccountProfileEditModal";
import AccountDescriptionModal from "../../Component/Modals/AccountPageModal/AccountDescriptionModal";
import {
  AccountProfile,
  AccountProfileDescription,
  AccountProfileImageEdit,
  AccountProfilePosts,
  UserAccountFollowerlist,
  UserAccountFollowinglist,
} from "../../Redux/ApiSlice/AccountProfileSlice";
import { useOutsideClick } from "../../Hooks/useOutsideClick";
import { MdKeyboardArrowDown } from "react-icons/md";
import { IoIosArrowUp } from "react-icons/io";

const theme = {
  dialog: {
    defaultProps: {
      size: "md",
      dismiss: {},
      animate: {
        unmount: {},
        mount: {},
      },
      className: "",
    },
    valid: {
      sizes: ["xs", "sm", "md", "lg", "xl", "xxl"],
    },
    styles: {
      base: {
        backdrop: {
          display: "grid",
          placeItems: "place-items-center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "w-screen",
          height: "h-screen",
          backgroundColor: "bg-black",
          backgroundOpacity: "bg-opacity-60",
          backdropFilter: "backdrop-blur-sm",
        },
        container: {
          position: "relative",
          bg: "bg-white",
          m: "m-4",
          borderRadius: "rounded-lg",
          boxShadow: "shadow-2xl",
          color: "text-blue-gray-500",
          fontSmoothing: "antialiased",
          fontSize: "text-base",
          fontWeight: "font-light",
          lineHeight: "leading-relaxed",
        },
      },
      sizes: {
        xs: {
          width: "w-full md:w-3/5 lg:w-2/5 2xl:w-1/4",
          minWidth: "min-w-[80%] md:min-w-[60%] lg:min-w-[40%] 2xl:min-w-[25%]",
          maxWidth: "max-w-[80%] md:max-w-[60%] lg:max-w-[40%] 2xl:max-w-[25%]",
        },
        sm: {
          width: "w-full md:w-2/3 lg:w-2/4 2xl:w-1/3",
          minWidth:
            "min-w-[80%] md:min-w-[66.666667%] lg:min-w-[50%] 2xl:min-w-[33.333333%]",
          maxWidth:
            "max-w-[80%] md:max-w-[66.666667%] lg:max-w-[50%] 2xl:max-w-[33.333333%]",
        },
        md: {
          width: "w-full md:w-3/4 lg:w-3/5 2xl:w-2/5",
          minWidth: "min-w-[90%] md:min-w-[75%] lg:min-w-[60%] 2xl:min-w-[40%]",
          maxWidth: "max-w-[90%] md:max-w-[75%] lg:max-w-[60%] 2xl:max-w-[40%]",
        },
        lg: {
          width: "w-full md:w-5/6 lg:w-3/4 2xl:w-3/5",
          minWidth:
            "min-w-[90%] md:min-w-[83.333333%] lg:min-w-[75%] 2xl:min-w-[60%]",
          maxWidth:
            "max-w-[90%] md:max-w-[83.333333%] lg:max-w-[75%] 2xl:max-w-[60%]",
        },
        xl: {
          width: "w-full md:w-5/6 2xl:w-3/4",
          minWidth: "min-w-[95%] md:min-w-[83.333333%] 2xl:min-w-[75%]",
          maxWidth: "max-w-[95%] md:max-w-[83.333333%] 2xl:max-w-[75%]",
        },
        xxl: {
          display: "flex",
          flexDirection: "flex-col",
          width: "w-screen",
          minWidth: "min-w-[100vw]",
          maxWidth: "max-w-[100vw]",
          height: "h-screen",
          minHeight: "min-h-[100vh]",
          maxHeight: "max-h-[100vh]",
          m: "m-0",
          borderRadius: "rounded-none",
        },
      },
    },
  },
};

const AccountProfileSection = React.memo(() => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedImg, setSelectedImg] = useState(null);
  const [showSettingIcon, setShowSettingIcon] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDecModal, setOpenDecModal] = useState(false);
  const [share, setShare] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const [switchTab, setSwitchTab] = useState(false);
  const switchTabRef = useRef(null);
  const ProfileDetails = useSelector((state) => state.AccountProfile);
  const {
    User_profile,
    loading,
    User_followingList,
    User_followersList,
    User_posts,
  } = ProfileDetails;

  const [accountDec, setAccountDec] = useState({
    about_us: User_profile.about_us,
  });

  const [ProfileName, setProfileName] = useState({
    first_name: "",
    last_name: "",
  });

  const openDrawer = () => setShowSettingIcon(true);
  const closeDrawer = () => setShowSettingIcon(false);
  const [openProfileEdit, setOpenProfileEdit] = useState(false);
  const handleOpen = () => setOpen(!open);
  const handleOpenDescription = () => setOpenDecModal(!openDecModal);

  const handleOpenProfileIcon = () => {
    setSelectedImg(null);
    setOpenProfileEdit(!openProfileEdit);
  };

  const handleSubmitDec = async (editDec) => {
    setAccountDec(editDec);
    handleOpenDescription();
    await dispatch(AccountProfileDescription(editDec));
    dispatch(AccountProfile());
  };

  const handleSubmitProfileImage = async (selected) => {
    handleOpenProfileIcon();
    setOpenProfileEdit(!openProfileEdit);
    await dispatch(AccountProfileImageEdit(selected));
    dispatch(AccountProfile());
  };

  const handleOpenShareModal = () => {
    setShare(!share);
  };

  useEffect(() => {
    dispatch(AccountProfile());
    dispatch(AccountProfilePosts(User_profile.id));
    setProfileName({
      first_name: User_profile.first_name || "",
      last_name: User_profile.last_name || "",
    });
    dispatch(UserAccountFollowerlist());
    dispatch(UserAccountFollowinglist());
  }, [
    dispatch,
    User_profile.first_name,
    User_profile.last_name,
    User_profile.id,
  ]);

  let originalURL = User_profile.avatar;
  const [show, setShow] = useState(false);
  const ref = useRef();

  const data = [
    {
      label: "Followers",
      value: "followers",
      icon: Square3Stack3DIcon,
      desc: <FollowersTab datalist={User_followersList} ref={ref} />,
    },
    {
      label: "Followings",
      value: "followings",
      icon: UserCircleIcon,
      desc: <FollowingTab datalist={User_followingList} ref={ref} />,
    },
    {
      label: "Posts",
      value: "posts",
      icon: Cog6ToothIcon,
      desc: <PostsTab User_posts={User_posts} User_profile={User_profile} activeTab={activeTab} />,
    },
  ];

  const handleSelect = (value) => {
    setActiveTab(value);
    setShow(!show);
  };

  const handleClickOutside = () => {
    setShow(null);
    setActiveTab("");
  };
  useOutsideClick(ref, handleClickOutside);


  useOutsideClick(switchTabRef, () => setSwitchTab(false));

  useEffect(() => {
    dispatch(getPublicPrivateToggle());
  }, []);

  return (
    <>
      <div className="grid grid-cols-12 px-2">
        <div className="col-span-6 flex items-center relative">
          <p className="text-[#fff] font-bold text-sm flex items-center">
            <span>
              @{User_profile.first_name} {User_profile.last_name}
            </span>
            <span>
              {switchTab ? <IoIosArrowUp onClick={() => setSwitchTab(!switchTab)} className="cursor-pointer text-[1rem]" /> : <MdKeyboardArrowDown onClick={() => setSwitchTab(!switchTab)} className="cursor-pointer text-[1rem]" />}
            </span>
          </p>
          {switchTab && (
            <div ref={switchTabRef} className="absolute top-6 z-50 left-10 bg-white px-2 py-1 rounded-lg">
              <p className="font-bold text-[12px] flex items-center text-green-500 hover:text-blue-600 cursor-pointer">
                <span>
                  @{User_profile.first_name} {User_profile.last_name}
                </span>
              </p>
              <p className="text-black font-bold text-[12px] flex items-center hover:text-blue-600 cursor-pointer">
                <span>
                  @Alexander
                </span>
              </p>
              <p className="text-black font-bold text-[12px] flex items-center hover:text-blue-600 cursor-pointer">
                <span>
                  @charles
                </span>
              </p>
            </div>
          )}
        </div>
        <div className="col-span-6 flex justify-between items-center">
          <p className="cursor-pointer">
            <FaShare color="white" onClick={() => handleOpenShareModal()} />
          </p>
          <div
            onClick={() => navigate("/account/updates")}
            class="relative inline-flex text-red-700 items-center cursor-pointer"
          >
            <IoNotifications color="white" />
            <span class="absolute rounded-full  text-[8px] font-medium content-[''] leading-none grid place-items-center top-[4%] right-[2%] translate-x-2/4 -translate-y-2/4 bg-red-500 text-white min-w-[14px] min-h-[14px]">
              5
            </span>
          </div>
          <div className="grid grid-cols-12 overflow-hidden">
            <div className="col-span-12">
              <AccountSidebar />
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-12 px-2 mt-2 relative">
        <div className="col-span-6 h-full">
          <div>
            <div className="rounded-[50%] flex flex-col">
              <div className="relative group">
                <img
                  alt="IMG"
                  src={originalURL}
                  className="h-[5rem] w-[5rem] rounded-[50%] object-cover"
                />
                <div className="absolute inset-0 z-10 items-center justify-center hidden h-[5rem] w-[5rem] rounded-[50%] object-cover transition-all duration-300 bg-black/10 group-hover:flex">
                  <button className="flex items-center justify-center  h-2 w-2 text-center rounded-full cursor-pointer ring-1  ring-transparent ">
                    <TbPhotoEdit
                      color="white"
                      onClick={handleOpenProfileIcon}
                    />
                  </button>
                </div>
              </div>
              <div>
                <p className="text-[#fff] font-bold text-sm flex mt-2">
                  <span>{User_profile.first_name}</span>
                  <span className="pl-2 cursor-pointer" onClick={handleOpen}>{User_profile.last_name}</span>
                  <span
                    className="pl-2 cursor-pointer"
                    onClick={() =>
                      navigate("/account/edit/profile")
                    }
                  >
                    <img src={Editicon} alt="IMG" />
                  </span>
                </p>
                <p
                  className="text-[#fff] font-medium text-[12px] mt-2 line-clamp-2 leading-[15px] cursor-pointer"
                  onClick={handleOpenDescription}
                >
                  {User_profile.about_us}
                  {User_profile.about_us ? "" : <span>ADD Decription</span>}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center col-span-6">
          <div className="flex flex-col justify-center items-center pb-5">
            <div className="relative flex justify-center gap-8">
              <div
                className="w-auto h-10  text-[8px] font-bold text-white justify-center items-center flex flex-col"
                onClick={() => handleSelect("followers")}
              >
                <p className="flex items-center justify-center relative w-full cursor-pointer">
                  <p className="absolute left-0 text-[12px]">
                    {User_followersList?.length}
                  </p>
                  <FaUserGroup className="text-[1rem]" />
                </p>
                <p className="text-[8px] font-bold  text-white mt-1 cursor-pointer">
                  #Followers
                </p>
              </div>
              <div
                className="w-auto h-10  text-[8px] font-bold  text-white justify-center items-center flex flex-col"
                onClick={() => handleSelect("followings")}
              >
                <p className="flex items-center justify-center relative w-full cursor-pointer">
                  <p className="absolute left-0 text-[12px]">
                    {User_followingList?.length}
                  </p>
                  <FaUserCheck className="text-[1rem]" />
                </p>
                <p className="text-[8px] font-bold  text-white mt-1 cursor-pointer">
                  #Following
                </p>
              </div>
            </div>
            <div className="w-full h-10  items-center mt-2 justify-center flex ">
              <div
                className="h-10  text-[8px] font-bold text-white justify-center items-center flex flex-col w-10 "
                onClick={() => handleSelect("posts")}
              >
                <p className="flex items-center justify-center  relative w-full">
                  <p className="absolute left-0 text-[12px] cursor-pointer">
                    {User_profile?.post_count}
                  </p>
                  <BsMailbox2 className="text-[1rem] cursor-pointer" />
                </p>
                <p className="text-[8px] font-bold  text-white mt-1 cursor-pointer">
                  #Posts
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {show && (
        <div className={"absolute h-full w-full top-0"}>
          <div
            className="absolute h-screen w-full top-0 opacity-100 bg-opacity-50 bg-gray-700 z-20 "
            onClick={handleClickOutside}
          ></div>
          <div
            ref={ref}
            className="absolute top-0 left-4 z-50 w-[90%] h-[-webkit-fill-available] mt-4 mb-3 flex justify-center rounded-lg  bg-black duration-1000 transform opacity-100"
          >
            <AccountTabModal
              ref={ref}
              data={data}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
            />
          </div>
        </div>
      )}

      <AccountNameEditModal
        open={open}
        handleOpen={handleOpen}
        theme={theme}
        User_profile={User_profile}
        loading={loading}
        ProfileName={ProfileName}
        setProfileName={setProfileName}
      />

      <AccountDescriptionModal
        open={openDecModal}
        handleOpen={handleOpenDescription}
        theme={theme}
        accountDec={accountDec}
        setAccountDec={setAccountDec}
        handleSubmitDec={handleSubmitDec}
      />

      <AccountProfileEditModal
        open={openProfileEdit}
        handleOpen={handleOpenProfileIcon}
        theme={theme}
        setSelectedImg={setSelectedImg}
        selectedImg={selectedImg}
        User_profile={User_profile}
        replacedURL={originalURL}
        dispatch={dispatch}
        handleSubmitProfileImage={handleSubmitProfileImage}
      />

      <AccountSettingModal
        openDrawer={openDrawer}
        open={showSettingIcon}
        closeDrawer={closeDrawer}
        User_profile={User_profile}
      />

      <ShareModal openBottom={share} closeDrawerBottom={handleOpenShareModal} />
    </>
  );
});

export default AccountProfileSection;
