import React, { useState } from "react";

export default function ReportModal({ setReportPost }) {
    const [blockUser, setBlockUser] = useState(false);
    const [reportReason, setReportReason] = useState("");


    return (
        <div className=" absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white py-4 px-4 rounded-lg shadow-lg  w-[80%]">
                <h2 className="text-[16px] font-bold text-black mb-2 flex justify-center items-center">Report & Block User</h2>
                <p className="text-gray-600 mb-2 text-[12px] text-center">
                    Please select an option below to either report, block, or both:
                </p>

                {/* Report Reason Dropdown */}
                <div className="mb-2">
                    <label htmlFor="reportReason" className="text-black font-medium text-[12px]">
                        Reason for Report:
                    </label>
                    <select
                        id="reportReason"
                        className="block w-full text-[14px] p-2 border border-gray-300 rounded-lg  text-gray-700 hover:bg-black  hover:text-white outline-none  hover:items-center transition-all duration-500 ease-in-out cursor-pointer"
                        onChange={(e) => setReportReason(e.target.value)}
                    >
                        <option value="" disabled selected>Select a reason</option>
                        <option value="spam">Spam</option>
                        <option value="harassment">Harassment or Bullying</option>
                        <option value="hateSpeech">Hate Speech</option>
                        <option value="nudity">Nudity or Sexual Content</option>
                        <option value="violence">Violence or Threats</option>
                        <option value="other">Other</option>
                    </select>
                </div>

                {/* Block User Checkbox */}
                <div className="my-2">
                    <label className="inline-flex items-center">
                        <input
                            type="checkbox"
                            className="form-checkbox text-red-500 outline-none checked:bg-red-500 checked:border-transparent"
                            onChange={(e) => setBlockUser(e.target.checked)}
                        />
                        <span className="ml-2 text-black text-[14px]">Block this user</span>
                    </label>
                </div>

                <div className="flex justify-center gap-4">
                    <button
                        className="rounded-full bg-[#151515] hover:bg-[#272727] text-[12px] font-bold px-10 h-7 text-[#fff]"
                        onClick={() => setReportPost(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className="rounded-full text-[12px] px-10 h-7 bg-[#d3d3d3] font-bold hover:bg-[#d3d3d3] text-[#000]"
                        onClick={() => setReportPost(false)}
                    >
                        Report
                    </button>
                </div>
            </div>
        </div>

    )
}

