

import React, { Fragment, useState } from "react";
import { Typography, List, ListItem, ListItemPrefix, Accordion, AccordionHeader, AccordionBody, Drawer, Card } from "@material-tailwind/react";
import { PresentationChartBarIcon, ShoppingBagIcon } from "@heroicons/react/24/solid";
import { ChevronDownIcon, MagnifyingGlassIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useLocation, useNavigate } from "react-router-dom";

import { IoFilter } from "react-icons/io5";
import { FaStar } from "react-icons/fa";

const FilterSideBar = () => {
    const [open, setOpen] = useState(0);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    const openDrawer = () => setIsDrawerOpen(true);
    const closeDrawer = () => setIsDrawerOpen(false);
    const categories = ['Electronics', 'Clothing', 'Books', 'Home & Kitchen'];
    const rating = [1, 2, 3, 4, 5]
    const brands = [
        { id: 1, name: 'Apple' },
        { id: 2, name: 'Samsung' },
        { id: 3, name: 'Nike' },
    ];
    const discounts = [
        { id: 1, label: 'Up to 10%', min: 0, max: 10 },
        { id: 2, label: '10% - 30%', min: 10, max: 30 },
        { id: 3, label: '30% - 50%', min: 30, max: 50 },
    ];
    const availability = [
        { id: 1, label: 'In Stock' },
        { id: 2, label: 'Out of Stock' },
    ];
    const colors = [
        { id: 1, name: 'Red' },
        { id: 2, name: 'Blue' },
        { id: 3, name: 'Black' },
    ];

    return (
        <Fragment>
            <button onClick={openDrawer}>
                {isDrawerOpen ? (
                    <XMarkIcon className={`h-6 w-6 stroke-2 ${location.pathname === "/wishlist" ? "text-[#000]" : "text-[#fff]"}`} />
                ) : (
                    <IoFilter className={`h-6 w-6 stroke-2 ${location.pathname === "/wishlist" ? "text-[#000]" : "text-[#fff]"}`} />
                )}

            </button>
            {isDrawerOpen && <Drawer
                open={isDrawerOpen}
                onClose={closeDrawer}
                placement="right"
                className="absolute overflow-y-scroll w-4/5"
                style={{ zIndex: 1, height: "100vh", maxHeight: "100vh" }}
            >
                <Card
                    color="transparent"
                    shadow={false}
                    className="overflow-scroll no-scrollbar"
                    style={{ zIndex: 1, height: "100vh", maxHeight: "100vh" }}
                >
                    <p className="text-[red] text-[8px] font-bold absolute top-0 w-full text-center">
                        CC SHOP HOME PAGE FILTER SIDEBAR , FE , V1 ,  SEP 17 ,2024
                    </p>
                    <div className="flex items-center gap-4 p-4 overflow-scroll no-scrollbar">
                        <Typography
                            variant="h5"
                            color="blue-gray"
                            onClick={() => navigate("/shop")}
                        >
                            FILTER
                        </Typography>
                    </div>
                    <div className="p-2 relative">
                        <MagnifyingGlassIcon className="h-5 w-5 absolute top-[17px] left-[12px] cursor-pointer" />
                        <input
                            type="text"
                            placeholder="Search"
                            className="border border-[#d9d9d9] w-full pl-7 pr-2 py-[0.4rem] focus:outline-none rounded-[5px]"
                        />
                    </div>
                    <List>
                        <Accordion
                            open={open === 1}
                            icon={
                                <ChevronDownIcon
                                    strokeWidth={2.5}
                                    className={`mx-auto h-4 w-4 transition-transform ${open === 1 ? "rotate-180" : ""}`}
                                />
                            }
                        >
                            <ListItem className="p-0" selected={open === 1}>
                                <AccordionHeader
                                    onClick={() => handleOpen(1)}
                                    className="border-b-0 p-3"
                                >
                                    <ListItemPrefix>
                                        <PresentationChartBarIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    <Typography color="blue-gray" className="mr-auto font-normal">
                                        Categories
                                    </Typography>
                                </AccordionHeader>
                            </ListItem>
                            <AccordionBody className="py-1">
                                <List className="p-0">
                                    {categories.map((data, index) => (
                                        <ListItem key={index}>
                                            <ListItemPrefix>
                                                <input type="checkbox" className="" />
                                            </ListItemPrefix>
                                            {data}
                                        </ListItem>
                                    ))
                                    }
                                </List>
                            </AccordionBody>
                        </Accordion>
                        <Accordion
                            open={open === 2}
                            icon={
                                <ChevronDownIcon
                                    strokeWidth={2.5}
                                    className={`mx-auto h-4 w-4 transition-transform ${open === 2 ? "rotate-180" : ""
                                        }`}
                                />
                            }
                        >
                            <ListItem className="p-0" selected={open === 2}>
                                <AccordionHeader
                                    onClick={() => handleOpen(2)}
                                    className="border-b-0 p-3"
                                >
                                    <ListItemPrefix>
                                        <ShoppingBagIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    <Typography color="blue-gray" className="mr-auto font-normal">
                                        Rating
                                    </Typography>
                                </AccordionHeader>
                            </ListItem>
                            <AccordionBody className="py-1 grid grid-cols-12">
                                {rating?.map((data) => (
                                    <List key={data} className="p-0 col-span-6">
                                        <ListItem >
                                            <ListItemPrefix>
                                                <input type="checkbox" className="" />
                                            </ListItemPrefix>
                                            {new Array(data).fill(data).map(() => (
                                                <FaStar />
                                            ))}
                                        </ListItem>
                                    </List>
                                ))
                                }
                            </AccordionBody>
                        </Accordion>
                        <hr className="my-2 border-blue-gray-50" />
                        <Accordion
                            open={open === 3}
                            icon={
                                <ChevronDownIcon
                                    strokeWidth={2.5}
                                    className={`mx-auto h-4 w-4 transition-transform ${open === 3 ? "rotate-180" : ""
                                        }`}
                                />
                            }
                        >
                            <ListItem className="p-0" selected={open === 3}>
                                <AccordionHeader
                                    onClick={() => handleOpen(3)}
                                    className="border-b-0 p-3"
                                >
                                    <ListItemPrefix>
                                        <ShoppingBagIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    <Typography color="blue-gray" className="mr-auto font-normal">
                                        Brands
                                    </Typography>
                                </AccordionHeader>
                            </ListItem>
                            <AccordionBody className="py-1">
                                {brands.map((item) => (
                                    <List className="p-0">
                                        <ListItem key={item.id}>
                                            <ListItemPrefix>
                                                <input type="checkbox" className="" />
                                            </ListItemPrefix>
                                            {item.name}
                                        </ListItem>
                                    </List>
                                ))
                                }
                            </AccordionBody>
                        </Accordion>
                        <Accordion
                            open={open === 4}
                            icon={
                                <ChevronDownIcon
                                    strokeWidth={2.5}
                                    className={`mx-auto h-4 w-4 transition-transform ${open === 4 ? "rotate-180" : ""
                                        }`}
                                />
                            }
                        >
                            <ListItem className="p-0" selected={open === 4}>
                                <AccordionHeader
                                    onClick={() => handleOpen(4)}
                                    className="border-b-0 p-3"
                                >
                                    <ListItemPrefix>
                                        <ShoppingBagIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    <Typography color="blue-gray" className="mr-auto font-normal">
                                        color
                                    </Typography>
                                </AccordionHeader>
                            </ListItem>
                            <AccordionBody className="py-1">
                                {colors.map((item) => (
                                    <List className="p-0">
                                        <ListItem key={item.id}>
                                            <ListItemPrefix>
                                                <input type="checkbox" className="" />
                                            </ListItemPrefix>
                                            {item.name}
                                        </ListItem>
                                    </List>
                                ))
                                }
                            </AccordionBody>
                        </Accordion>
                        <Accordion
                            open={open === 5}
                            icon={
                                <ChevronDownIcon
                                    strokeWidth={2.5}
                                    className={`mx-auto h-4 w-4 transition-transform ${open === 5 ? "rotate-180" : ""
                                        }`}
                                />
                            }
                        >
                            <ListItem className="p-0" selected={open === 5}>
                                <AccordionHeader
                                    onClick={() => handleOpen(5)}
                                    className="border-b-0 p-3"
                                >
                                    <ListItemPrefix>
                                        <ShoppingBagIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    <Typography color="blue-gray" className="mr-auto font-normal">
                                        Discount
                                    </Typography>
                                </AccordionHeader>
                            </ListItem>
                            <AccordionBody className="py-1">
                                {discounts.map((item) => (
                                    <List className="p-0">
                                        <ListItem key={item.id}>
                                            <ListItemPrefix>
                                                <input type="checkbox" className="" />
                                            </ListItemPrefix>
                                            {item.label}
                                        </ListItem>
                                    </List>
                                ))
                                }
                            </AccordionBody>
                        </Accordion>
                        <Accordion
                            open={open === 6}
                            icon={
                                <ChevronDownIcon
                                    strokeWidth={2.5}
                                    className={`mx-auto h-4 w-4 transition-transform ${open === 6 ? "rotate-180" : ""
                                        }`}
                                />
                            }
                        >
                            <ListItem className="p-0" selected={open === 6}>
                                <AccordionHeader
                                    onClick={() => handleOpen(6)}
                                    className="border-b-0 p-3"
                                >
                                    <ListItemPrefix>
                                        <ShoppingBagIcon className="h-5 w-5" />
                                    </ListItemPrefix>
                                    <Typography color="blue-gray" className="mr-auto font-normal">
                                        Availability
                                    </Typography>
                                </AccordionHeader>
                            </ListItem>
                            <AccordionBody className="py-1">
                                {availability.map((item) => (
                                    <List className="p-0">
                                        <ListItem key={item.id}>
                                            <ListItemPrefix>
                                                <input type="checkbox" className="" />
                                            </ListItemPrefix>
                                            {item.label}
                                        </ListItem>
                                    </List>
                                ))
                                }
                            </AccordionBody>
                        </Accordion>

                    </List>
                </Card>
            </Drawer>}
        </Fragment>
    );
};


export default FilterSideBar