import React, { useState } from 'react'
import DisplaySetting from '../Home/DisplaySetting';

export default function Display() {
    const [openDisplaySettings, setOpenDisplaySettings] = useState(true);
    return (
        <DisplaySetting
            openDisplaySettings={openDisplaySettings}
            setOpenDisplaySettings={setOpenDisplaySettings}
        />
    )
}
