import React, { useEffect, useRef } from "react";
import { AiTwotoneMessage } from "react-icons/ai";
import { Button } from "@material-tailwind/react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LazyLoad from "react-lazyload";
import {
  AccountProfile,
  AccountProfilePosts,
  // OtherUserProfile,
  // UserAccountFollow,
  UserAccountFollowerlist,
  UserAccountFollowinglist,
} from "../../Redux/ApiSlice/AccountProfileSlice";
import { IoArrowBackCircle } from "react-icons/io5";
import { FaUserGroup } from "react-icons/fa6";
import { FaShare, FaUserCheck } from "react-icons/fa";
import { BsMailbox2 } from "react-icons/bs";

export default function ProfileSection({
  userId,
  setCount,
  count,
  handleFollowFun,
  otherUserFollowingYou,
  handleStartChat,
  handleShare,
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const ProfileDetails = useSelector((state) => state.AccountProfile);
  // const Messages = useSelector((state) => state.messages);
  const { User_profile, otherUser_profile } = ProfileDetails;
  // const { createChatRoom } = Messages;
  const {
    first_name,
    last_name,
    about_us,
    avatar,
    post_count,
    following_count,
    followers_count,
    is_private,
    is_following,
  } = otherUser_profile;

  const handleNavigate = (value) => {
    if ((is_private && is_following && otherUserFollowingYou) || !is_private) {
      setCount(`${value}`);
    }
  };

  const handleBack = () => {
    if (count === "allPosts") {
      navigate(-1);
    } else {
      setCount("allPosts");
    }
  };

  const path = location?.pathname?.split("/");
  const label = path[path.length - 2];

  const imgRef = useRef();
  useEffect(() => {
    const interval = setInterval(() => {
      if (imgRef.current) {
        imgRef.current.classList.add("flip");

        setTimeout(() => {
          if (imgRef.current) {
            imgRef.current.classList.remove("flip");
          }
        }, 1500); // Adjusted timing
      }
    }, 10000);

    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    dispatch(AccountProfile());
    dispatch(AccountProfilePosts(User_profile.id));
    dispatch(UserAccountFollowerlist());
    dispatch(UserAccountFollowinglist());
  }, [dispatch, User_profile.id]);

  return (
    <>
      <p className="text-[#ff3b3b] text-center w-full text-[8px] mb-1 font-[700] flex justify-center z-50 lg:text-[8px] uppercase">
        CC ACCOUNT {`/ ${label}`} /FE  /V10 / OCT 11, 2024
      </p>
      <div className="flex justify-between items-center px-2 mb-1">
        <IoArrowBackCircle
          className="text-[23px] text-white cursor-pointer"
          onClick={() => handleBack()}
        />
        <div className="flex items-center gap-4">
          <FaShare color="white" className="cursor-pointer" onClick={handleShare} />
          <div class="relative inline-flex items-center ">
            <AiTwotoneMessage className="text-[1.5rem] cursor-pointer" onClick={() => handleStartChat()}
            />
          </div>
        </div>
      </div>

      <div className="w-full h-[0.5px] bg-[#313131]" />

      <div className="grid grid-cols-12 px-2 mt-2 relative ">
        <div className="col-span-6 h-full">
          <div className="rounded-[50%] flex flex-col">
            <div className="relative group">
              <LazyLoad height={200} offset={100}>
                <img
                  ref={imgRef}
                  alt={first_name}
                  src={avatar}
                  className="h-[4.8rem] w-[4.8rem] rounded-full object-cover"
                />
              </LazyLoad>
            </div>
            <div className="mt-2">
              <p className="text-[#fff] font-bold text-sm flex">
                {first_name} {last_name}
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col col-span-6">
          <div className="flex flex-col justify-center items-center ">
            <div className="relative flex justify-center gap-8">
              <div
                onClick={() => handleNavigate("Followers")}
                className="w-auto h-10  text-[8px] font-bold  text-white justify-center items-center flex flex-col"
              >
                <p className="flex items-center justify-center relative w-full cursor-pointer">
                  <p className="absolute left-[4px] top-[0.3px] text-[10px]">
                    {followers_count}
                  </p>
                  <FaUserGroup className="text-[13px]" />
                </p>
                <p className="text-[10px] font-bold text-white cursor-pointer">
                  followers
                </p>
              </div>
              <div
                onClick={() => handleNavigate("Following")}
                className="w-auto h-10 text-[8px] font-bold text-white justify-center items-center flex flex-col"
              >
                <p className="flex items-center justify-center relative w-full cursor-pointer">
                  <p className="absolute left-[4px] top-[0.3px] text-[10px]">
                    {following_count}
                  </p>
                  <FaUserCheck className="text-[13px]" />
                </p>
                <p className="text-[10px] font-bold text-white cursor-pointer">
                  following
                </p>
              </div>
            </div>
            <div className="w-full h-10 items-center justify-center flex ">
              <div className="h-10 text-[8px] font-bold text-white justify-center items-center flex flex-col w-10">
                <p className="flex items-center justify-center  relative w-full">
                  <p
                    onClick={() => handleNavigate("Posted")}
                    className="absolute left-[4px] top-[0.3px] text-[10px] cursor-pointer"
                  >
                    {post_count}
                  </p>
                  <BsMailbox2
                    onClick={() => handleNavigate("Posted")}
                    className="text-[13px] cursor-pointer"
                  />
                </p>
                <p className="text-[10px] font-bold  text-white cursor-pointer">
                  posts
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12">
          <p className="text-[#959595] font-medium text-[12px] mt-[6px] line-clamp-2 leading-[14px]">
            {about_us}
          </p>
        </div>
      </div>

      <div className="px-2 mt-2">
        <Button
          size="sm"
          onClick={handleFollowFun}
          className={`rounded-lg w-full ${is_following
            ? "bg-[#1b1b1b] hover:bg-[#0f0f0f]"
            : "bg-blue-500 hover:bg-blue-600"
            } text-white`}
        >
          {is_following ? "UnFollow" : "Follow"}
        </Button>
      </div>
      <div className="p-0 overflow-y-scroll no-scrollbar absolute w-full h-[-webkit-fill-available]"></div>
    </>
  );
}
