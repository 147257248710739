import React, { Fragment, useRef, useState } from "react";
import { AiFillLike } from "react-icons/ai";
import { FaCommentDots } from "react-icons/fa";
import { FaShareSquare } from "react-icons/fa";
import { HiDotsVertical } from "react-icons/hi";
import OtherGroupProfileSection from "../Component/OtherUserGroup/OtherGroupProfileSection";
import OtherGroupTab from "../Component/OtherUserGroup/OtherGroupTab";
import { Card } from "@material-tailwind/react";
import { useOutsideClick } from "../Hooks/useOutsideClick";
import { useNavigate } from "react-router-dom";

const OtherUserGroupsPage = () => {
   const navigate = useNavigate();
   const [menuId, setMenuId] = useState(0);
   const menuRef = useRef(null);

   const handleSelectMenu = (id) => setMenuId(id);
   useOutsideClick(menuRef, () => setMenuId(0));
   return (
      <Fragment>
         <section className="h-full overflow-hidden bg-black ">
            <div className="w-full flex justify-center items-center absolute top-0">
               <p className="text-[#ff3b3b] text-center font-bold text-[10px] uppercase">
                  CC / OTHERS GROUP HOMEPAGE / FE / V6 / oct 11, 2024
               </p>
            </div>

            <OtherGroupProfileSection />
            <OtherGroupTab />

            <div className="h-[55%] md:h-[50%] overflow-y-scroll">
               <div className="grid grid-cols-12 px-3 pt-2 pb-3">
                  <div className="col-span-2">
                     <img
                        alt="profilepic"
                        className="h-12 md:h-10 w-12 md:w-10 rounded-full object-cover"
                        src="https://images.unsplash.com/photo-1640951613773-54706e06851d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                     />
                  </div>
                  <div className="col-span-9">
                     <p className="text-white text-[18px] md:text-[15px]">John Smith</p>
                     <p className="text-[#3ea8d8] text-[15px] md:text-[12px] font-bold">10 Day Ago</p>
                  </div>
                  <div className="col-span-1 relative">
                     <HiDotsVertical
                        className="text-white text-[25px] absolute left-2 cursor-pointer"
                        onClick={() => handleSelectMenu(1)}
                     />
                     {menuId === 1 && (
                        <Card
                           className="w-24 absolute top-4 right-4 rounded-md p-1 text-black bg-white z-10"
                           ref={menuRef}
                        >
                           <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                              Save
                           </p>
                           <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                              Hide
                           </p>
                           <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                              Block User
                           </p>
                           <p
                              className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]"
                              onClick={() => navigate("/othergroup/postreport")}
                           >
                              Report
                           </p>
                        </Card>
                     )}
                  </div>

                  <div className="col-span-2"></div>
                  <div className="col-span-10">
                     <p className="text-white text-[15px]">
                        "Hey guys, Look at this car. Today I buy this car, It's a
                        verycool toy"
                     </p>
                     <img
                        alt="postimg"
                        className="w-full object-cover pt-2 rounded"
                        src="https://images.unsplash.com/photo-1546768292-fb12f6c92568?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                     />
                  </div>

                  <div className="col-span-12">
                     <div className="flex justify-end gap-[10px] pt-[6px]">
                        <AiFillLike className="text-white cursor-pointer hover:text-[#31c629]" />
                        <FaCommentDots
                           className="text-white cursor-pointer hover:text-[#31c629]"
                           onClick={() => navigate("/othergroup/post/comment")}
                        />
                        <FaShareSquare className="text-white cursor-pointer hover:text-[#31c629]" />
                     </div>
                  </div>
               </div>

               <div className="grid grid-cols-12 px-3 pt-1 pb-3">
                  <div className="col-span-2">
                     <img
                        alt="profilepic"
                        className="h-12 md:h-10 w-12 md:w-10 rounded-full object-cover"
                        src="https://images.unsplash.com/photo-1500336624523-d727130c3328?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                     />
                  </div>
                  <div className="col-span-9">
                     <p className="text-white text-[18px] md:text-[15px]">Emily Johnson</p>
                     <p className="text-[#3ea8d8] text-[15px] md:text-[12px] font-bold">
                        103 Day Ago
                     </p>
                  </div>
                  <div className="col-span-1 relative">
                     <HiDotsVertical
                        className="text-white text-[25px] absolute left-2 cursor-pointer"
                        onClick={() => handleSelectMenu(2)}
                     />
                     {menuId === 2 && (
                        <Card
                           className="w-24 absolute top-4 right-4 rounded-md p-1 text-black bg-white z-10"
                           ref={menuRef}
                        >
                           <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                              Save
                           </p>
                           <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                              Hide
                           </p>
                           <p className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]">
                              Block User
                           </p>
                           <p
                              className="p-1 pl-2 cursor-pointer hover:bg-gray-200 hover:rounded-md text-[12px]"
                              onClick={() => navigate("/othergroup/postreport")}
                           >
                              Report
                           </p>
                        </Card>
                     )}
                  </div>

                  <div className="col-span-2"></div>
                  <div className="col-span-10">
                     <p className="text-white text-[15px]">
                        "Guys, Look at my new car how it is!! Give some feedback."
                     </p>
                     <img
                        alt="postimg"
                        className="w-full object-cover mt-2 rounded"
                        src="https://images.unsplash.com/photo-1514867644123-6385d58d3cd4?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                     />
                  </div>

                  <div className="col-span-12">
                     <div className="flex justify-end gap-[10px] pt-[6px]">
                        <AiFillLike className="text-white cursor-pointer hover:text-[#31c629]" />
                        <FaCommentDots
                           className="text-white cursor-pointer hover:text-[#31c629]"
                           onClick={() => navigate("/othergroup/post/comment")}
                        />
                        <FaShareSquare className="text-white cursor-pointer hover:text-[#31c629]" />
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </Fragment>
   );
};

export default OtherUserGroupsPage;