import React, { useEffect, useState } from "react";
// import storeimage from "../Assets/LoginPageIcon/Download Buttons.svg";
import { useNavigate } from "react-router-dom";
// import EnterOtp from "../Components/MobileVersion/OtpPage/OtpPage";
// import OtpInput from "react-otp-input";
// import OTPInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { enterOtp } from "../Redux/ApiSlice/EnterOtpSlice";
import SecretSauceButton from "../Components/MobileVersion/SecretSauceButton/SecretSauceButton";
import Footer from "../Component/Footer/Footer";
import HeaderTab from "../Component/Login/LoginHeader";

const EnterOTP = () => {
  const navigate = useNavigate();
  // const [code, setCode] = useState("");

  // const handleChange = (code) => setCode(code);

  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();

  // const handleChange = (otp) => {
  //   setOtp(otp);
  // };

  const handleChange = (value) => {
    setOtp((prev) => `${prev}${value}`);
  };

  const handleClick = (value) => {
    navigate(`/${value}`);
  };
  // const renderInput = (value, index) => (
  //   <input
  //     key={index}
  //     type="text"
  //     maxLength="1"
  //     value={value}
  //     className="w-12 h-12 bg-neutral-200 outline-none pl-6 "
  //     onChange={(e) => handleChange(e, value)}
  //   />
  // );
  useEffect(() => {
    if (otp.length === 6) {
      dispatch(enterOtp({ email: "syedb1005@tutanota.com", otp: "123456" }));
    }
  }, [otp, dispatch]);

  // const renderInput = (value, index) => (
  //   <input
  //     key={index}
  //     type="text"
  //     maxLength="1"
  //     className="w-12 h-12 bg-neutral-200 outline-none pl-6 "
  //     value={otp}
  //     onChange={(e) => handleChange(e.target.value)}
  //   />
  // );
  return (
    <>
    {/* important code do not remove */}
      {/* <div className="hidden overflow-hidden bg-[#74cbfc] h-[100vh]">
        <p className="absolute uppercase text-[#ff3b3b] right-5 font-semibold">
          CC Enter OTP / V1 / FE / Mar 05, 2024
        </p>

        <HeaderTab />

        <div className="flex justify-center items-center mt-[4rem]">
          <div className="w-[45%] h-[80%] bg-[#fff] rounded-2xl relative flex justify-center items-center shadow-2xl">
            <div className="p-4">
              <p className="text-center text-[2.5rem] font-bold mt-2 tracking-wide">
                Enter OTP
              </p>
              <p className="text-center text-[0.9rem] font-semibold mt-2">
                We sent a OTP mail to{" "}
                <span className="text-[#00A3FA]">marydavis@example.com</span>
              </p>
              <p className="text-center text-[0.9rem] font-semibold mb-3">
                Please Enter OTP here
              </p>
              <form className="px-14 mt-7">
                <div className="flex justify-evenly gap-x-10 mb-3 bg-black">
               
                </div>

                <div className="flex justify-between items-center mt-3 mb-5">
                  <p
                    className="font-medium cursor-pointer text-[#00A3FA]"
                    onClick={() => navigate("/commingsoon")}
                  >
                    Resend OTP
                  </p>
                  <p
                    className="font-medium cursor-pointer text-[#00A3FA]"
                    onClick={() => navigate("/changepassword")}
                  >
                    It's not my mail
                  </p>
                </div>
                <div className="flex justify-center items-center mb-10">
                  <button
                    className="bg-[#00A3FF] text-[#fff] text-[1rem] font-semibold w-[25%] shadow-lg py-[7px] rounded-lg"
                    onClick={() => navigate("/resetpassword")}
                  >
                    Back
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </div> */}
      <main className="w-full h-screen md:h-full flex justify-center items-center overflow-hidden">
        <div className="h-screen md:h-full w-full relative">
          <div className="h-[85%] grid grid-cols-12">
            <div className=" col-span-12 flex  bg-[#fff] flex-col  items-center  border-black">
              <div className="flex justify-center font-semibold text-sm  bg-[#fff] items-center ">
                <p className="text-[#ff3b3b] absolute top-0 w-full text-center font-extrabold text-[14px] md:text-[10px]">
                  CC LOST MY PASSWORD OTP - FE - V3 - OCT 11, 2024
                </p>
              </div>
              <p className="text-[5rem] md:text-[4rem]">😊</p>
              <h1 className="text-[#00A3FF] text-[6rem] md:text-[5rem] font-bold">CC</h1>
              <h3 className="font-extrabold text-xl md:text-md">Enter OTP</h3>
              <p className="text-xl md:text-sm font-semibold text-center w-full m-4 md:m-2 px-[4%]">
                We sent a one-time passcode to
                <span className="text-[#00A3FF] pl-1">marydavis@gmail.com</span>.
                Please enter your code below
              </p>
            </div>
            <div className="col-span-12 overflow-auto w-full bg-[#fff]">
              <div className="h-full p-3 flex flex-col items-center">
                <form className="w-full">
                  <label className="text-base font-bold mb-2">
                    marydavis@gmail.com
                  </label>
                  <div className="h-[4rem] flex flex-row gap-3 items-center">
                    <input
                      className="w-2/12 bg-gray-100  h-3/4"
                      type="text"
                      placeholder=""
                      maxLength={1}
                    />
                    <input
                      className="w-2/12 bg-gray-100  h-3/4"
                      type="text"
                      placeholder=""
                      maxLength={1}
                    />
                    <input
                      className="w-2/12 bg-gray-100  h-3/4"
                      type="text"
                      placeholder=""
                      maxLength={1}
                    />
                    <input
                      className="w-2/12 bg-gray-100  h-3/4"
                      type="text"
                      placeholder=""
                      maxLength={1}
                    />
                    <input
                      className="w-2/12 bg-gray-100  h-3/4"
                      type="text"
                      placeholder=""
                      maxLength={1}
                    />
                    <input
                      className="w-2/12 bg-gray-100  h-3/4"
                      type="text"
                      placeholder=""
                      maxLength={1}
                    />
                  </div>
                  <div className="flex justify-between mt-2 mb-2">
                    <p className="text-[#00A3FF] text-[12px] font-bold">
                      Resend OTP
                    </p>
                    <p
                      className="text-[#00A3FF] text-[12px] font-bold z-20"
                      onClick={() => navigate("/changepassword")}
                    >
                      Re-Entry My Email
                    </p>
                  </div>
                </form>
                <div className="flex justify-center items-center">
                  <button
                    className="bg-black text-[#fff] px-5 py-2 rounded-md font-bold"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                </div>
              </div>
            </div>
          </div>
          <SecretSauceButton />
          <div className=" flex justify-center items-center h-[10%] md:h-[7%] bg-[black]">
            <button
              className="rounded-[2rem] px-4 py-1 bg-[#D9D9DE]  font-bold text-base"
              onClick={() => handleClick("register")}
            >
              Register
            </button>
          </div>
        </div>
      </main>
    </>
  );
};

export default EnterOTP;
