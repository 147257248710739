import React, { useState, useRef } from "react";
import { FaArrowAltCircleLeft } from "react-icons/fa";
import { FaRegPlusSquare } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
// import Cropper from "react-easy-crop";
import AccountProfileImageCrop from "../Modals/AccountPageModal/AccountProfileImageCrop";
import {
   Button,
   Dialog,
   // DialogHeader,
   DialogFooter,
   // Typography,
   Avatar,
} from "@material-tailwind/react";
import { TbPhotoEdit } from "react-icons/tb";

const CreateGroup = () => {
   const navigate = useNavigate();
   const fileInputRef = useRef(null);

   const [count, setCount] = useState(0);
   const [isChecked, setIsChecked] = useState(false);
   const [open, setOpen] = useState(false);

   const [selectedImage, setSelectedImage] = useState(null); // State to store selected image

   const handleIconClick = () => {
      fileInputRef.current.click();
   };

   // const [crop, setCrop] = React.useState({ x: 0, y: 0 });
   // const [zoom, setZoom] = React.useState(1);
   const [showImage, setShowImage] = useState(null);
   // const onCropChange = (crop) => {
   //    setCrop(crop);
   // };

   const handleOpen = () => {
      setOpen(!open);
   };
   const handleClose = () => {
      setOpen(false);
      setShowImage(true);
   }
   const handleFileInputChange = (event) => {
      const selectedFile = event.target.files[0];
      // Store selected image in state
      const file = event.target.files[0];
      if (!file) return;
      const fileUrl = URL.createObjectURL(file);
      setSelectedImage(fileUrl);
      setOpen(!open);
   };

   // const onCropComplete = (croppedArea, croppedAreaPixels) => {
   // };

   // const onZoomChange = (zoom) => {
   //    setZoom(zoom);
   // };

   // 50 textarea character
   const handleChange = (event) => {
      const inputText = event.target.value;
      if (inputText.length <= 50) {
         setCount(inputText.length);
      }
   };

   // checkbox toggle button
   const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
   };

   return (
      <div className="px-4">
         <FaArrowAltCircleLeft
            onClick={() => navigate(-1)}
            className="text-white text-[18px] cursor-pointer"
         />
         <p className="text-white text-center text-[22px] font-semibold md:text-[18px]">
            Create Group
         </p>
         <form className="mt-2">
            <div className="flex flex-col">
               <label className="text-white mb-1 text-[18px] md:text-[13px]">
                  Group name
               </label>
               <input
                  type="text"
                  className="focus:outline-none bg-[#D9D9D9] rounded-[8px] py-2 px-4 md:py-1 md:px-2"
               />
            </div>

            <div className="flex justify-center items-center mt-5 mb-3">
               <input
                  type="file"
                  ref={fileInputRef}
                  accept="image/*"
                  className="hidden"
                  onChange={handleFileInputChange}
               />
               {!showImage && <div className="flex justify-center items-center rounded-full h-[8rem] w-[8rem] bg-[#D9D9D9] md:h-[6rem] md:w-[6rem]">
                  <FaRegPlusSquare
                     onClick={handleIconClick}
                     className="text-[#1d1d1d] text-[23px] cursor-pointer"
                  />
               </div>}
               {showImage &&<div className="rounded-[50%] flex flex-col">
                     <div className="relative group">
                        <Avatar src={selectedImage} className="h-[8rem] w-[8rem] md:h-[6rem] md:w-[6rem]" />
                        <div className="absolute inset-0 z-10 items-center justify-center hidden h-[8rem] w-[8rem] md:h-[6rem] md:w-[6rem] rounded-[50%] object-cover transition-all duration-300 bg-black/10 group-hover:flex">
                           <button className="flex items-center justify-center  h-2 w-2 text-center rounded-full cursor-pointer ring-1  ring-transparent ">
                              <TbPhotoEdit
                                 color="white"
                                 onClick={handleIconClick}
                              />
                           </button>
                        </div>
                     </div>
                     </div>}
               <Dialog
                  // theme={500}
                  open={open}
                  size={300}
                  handler={handleOpen}
                  className={`${selectedImage ? "h-[50%]" : ""}  bg-0 absolute top-[20%] border-0`}
               >
                  <AccountProfileImageCrop
                     selectedImg={selectedImage}
                     handleOpen={handleOpen}
                     handleSubmitProfileImage={handleOpen}
                     isCreateGroup={true}
                  />
                  <DialogFooter>
                     <div className="w-full flex justify-center h-10 gap-10 ">
                        <Button
                           className="bg-[hsl(0,0%,60%)] w-[25%] flex justify-center items-center  rounded-3xl text-[#000] font-bold text-[18px]  cursor-pointer "
                           onClick={() => handleClose()}
                        >
                           Save
                        </Button>

                        <Button
                           className="bg-[hsl(0,0%,60%)] w-[25%] flex justify-center items-center text-[#000] text-[18px] font-bold  rounded-3xl cursor-pointer"
                           onClick={() => handleClose()}
                        >
                           Cancel
                        </Button>
                     </div>
                  </DialogFooter>
               </Dialog>
            </div>

            <div className="flex flex-col mb-2">
               <label className="text-white mb-1 text-[18px] md:text-[13px]">
                  Group description
               </label>
               <div className="relative">
                  <textarea
                     rows="5"
                     maxLength={50}
                     onChange={handleChange}
                     placeholder="Type Something"
                     className="w-full bg-[#D9D9D9] px-[10px] py-[5px] focus:outline-none resize-none rounded-[8px]"
                  />
                  <p className="absolute text-black text-[12px] bottom-2 right-2">
                     {count}/50
                  </p>
               </div>
            </div>

            <div className="flex flex-col mb-3">
               <label className="text-white mb-1 text-[18px] md:text-[13px]">
                  Group moto
               </label>
               <input
                  type="text"
                  className="focus:outline-none bg-[#D9D9D9] rounded-[8px] py-2 px-4 md:py-1 md:px-2"
               />
            </div>

            <div className="flex flex-col mb-2">
               <label className="text-white mb-1 text-[18px] md:text-[13px]">
                  Rules to follow
               </label>
               <div className="relative">
                  <textarea
                     rows="5"
                     maxLength={50}
                     onChange={handleChange}
                     placeholder="Type Something"
                     className="w-full bg-[#D9D9D9] px-[10px] py-[10px] focus:outline-none resize-none rounded-[8px] md:py-[5px] md:px-[10px]"
                  />
                  <p className="absolute text-black text-[12px] bottom-2 right-2">
                     {count}/50
                  </p>
               </div>
            </div>

            <div className="flex justify-between mb-2">
               <p className="text-white text-[18px] md:text-[13px]">
                  Private
               </p>
               <label className="relative inline-flex cursor-pointer select-none items-center">
                  <input
                     type="checkbox"
                     name="autoSaver"
                     className="sr-only"
                     checked={isChecked}
                     onChange={handleCheckboxChange}
                  />
                  <span className={`slider flex h-[26px] w-[50px] md:h-[20px] md:w-[45px] items-center rounded-full p-1 duration-200 ${isChecked ? "bg-[#3ccc2f]" : "bg-[#CCCCCE]"}`}>
                     <span className={`dot h-[18px] w-[14px] md:w-[12x] md:h-[14px] rounded-full duration-200 ${isChecked ? "translate-x-6 bg-[#D9D9D9]" : "bg-black"}`}></span>
                  </span>
               </label>
            </div>
         </form>
         <div className="flex justify-between mt-5 pb-5">
            <button
               onClick={() => navigate("/invitemember")}
               className="bg-[#D9D9D9] text-black hover:font-bold rounded-full w-[30%] py-1 text-[12px]"
            >
               Next
            </button>
            <button className="bg-[#D9D9D9] text-black hover:font-bold rounded-full w-[30%] py-1 text-[12px]" onClick={() => navigate(-1)}>
               Cancel
            </button>
         </div>
      </div>
   );
};

export default CreateGroup;
