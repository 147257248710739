import React, { Fragment } from "react";
import FilterSideBar from "./FilterSideBar";
import { useNavigate } from "react-router-dom";

const InputButton = () => {
   const navigate = useNavigate();
   return (
      <Fragment>
         <div className="px-2 grid grid-cols-10 mt-4 mb-3">
            <div className="col-span-8">
               <input
                  placeholder="Search for products here…"
                  className="bg-[#fff] rounded-3xl text-[#000] w-full py-1 px-3 focus:outline-none placeholder:text-[14px]"
               />
            </div>
            <div className="col-span-2 flex justify-end ">
               <FilterSideBar />
            </div>
         </div>
         <div className="flex items-center justify-between px-2 gap-2">
            <button className="w-[35%] bg-[#fff] text-[#000] text-[13px] px-5 md:px-2 py-1 rounded-full">
               Popular
            </button>
            <button className="w-[35%] bg-[#fff] text-[#000] text-[13px] px-5 md:px-2 py-1 rounded-full">
               Trending
            </button>
            <button className="w-[35%] bg-[#474747] text-[#fff] text-[13px] px-5 md:px-2 py-1 rounded-full">
               Discount
            </button>
            <button
               onClick={() => navigate("/order")}
               className="w-[35%] bg-[#fff] text-[#000] text-[13px] px-5 md:px-2 py-1 rounded-full"
            >
               Order
            </button>
         </div>
      </Fragment>
   );
};

export default InputButton;
