

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaBasketShopping } from "react-icons/fa6";
import { MdGroups, MdOutlineFeed } from "react-icons/md";
import { CiStreamOn } from "react-icons/ci";
import { IoIosSearch } from "react-icons/io";
import { GiThreeFriends } from "react-icons/gi";
import { useNavigate, useParams } from "react-router-dom";
import { AccountProfile, UserlikedVideos } from "../../Redux/ApiSlice/AccountProfileSlice";
import { HomepageVideo } from "../../Redux/ApiSlice/HomePageVideoSlice";
import SecretSauceButton from "../../Components/MobileVersion/SecretSauceButton/SecretSauceButton";
import Post from "../../Component/videopage/Post";
import ProfileAccountModal from "../../Component/Modals/HomePageModal/ProfileAccountModal";
import VideoCardIconList from "../../Components/MobileVersion/MobilePanel/VideoCardIconList";
import { VideoCardFooterIcons } from "../../Datas/Datas";
import HomePagePannel from "../../Components/Panels/HomePagePanel";

export default function WatchVideo() {
   const params = useParams();
   const { id } = params;
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const getAllPost = useSelector((state) => state.WatchVideo);
   const { AllPosts } = getAllPost;
   const [setLikeLikeEmoji] = useState(false);

   const hashTag = "#crazy #money #plane #toy";
   const spilThashTags = hashTag.split("#");
   const summary = " Guess how much this dude spent on this?";
   const [nextButton, setNextButton] = useState(null);
   const [preButton, setPreButton] = useState(null);

   const fetchData = useCallback(async () => {
      await dispatch(UserlikedVideos());
      await dispatch(AccountProfile());
      dispatch(HomepageVideo());
   }, [dispatch]);

   useEffect(() => {
      fetchData();
   }, [fetchData, dispatch]);

   const [openBottom, setOpenBottom] = useState(false);
   const openDrawerBottom = () => setOpenBottom(true);
   const closeDrawerBottom = () => setOpenBottom(false);
   const [openAccountBottom, setOpenAccountBottom] = useState(false);
   const openDrawerAccountModal = () => setOpenAccountBottom(!openAccountBottom);

   const reloadPage = () => {
      // For Reload the page
      window.location.reload();
   };

   const handleNavigate = (value) => {
      const replaceValue = value.replaceAll(" ", "");
      switch (replaceValue) {
         case "Messages":
            return navigate(`/mobileMessages`);
         case "YourFeed":
            return reloadPage();;
         case "YourFriends":
            return reloadPage();;
         case "Home":
            return navigate("/home");
         case "Createvideo":
            return openDrawerBottom();
         case "YourCC":
            return navigate(`/commingsoon`);
         case "Helmet1":
            return navigate(`/commingsoon`);
         case "Account":
            // return openDrawerAccountModal();
            return navigate(`/account`)
         case "Groups":
            return navigate("/othergroup/list");
         default:
            navigate(`/${replaceValue}`);
            break;
      }
   };

   const HeaderIcons = [
      {
         icon: <FaBasketShopping color="white" className="h-[20px] w-[20px]" />,
         placeholder: "Shop",
      },
      {
         icon: <MdOutlineFeed color="white" className="h-[20px] w-[20px]" />,
         placeholder: "Your Feed",
      },
      {
         icon: <GiThreeFriends color="white" className="h-[20px] w-[20px]" />,
         placeholder: "Your Friends",
      },
      {
         icon: <CiStreamOn color="white" className="h-[20px] w-[20px]" />,
         placeholder: "Live Stream",
      },
      {
         icon: <IoIosSearch color="white" className="h-[20px] w-[20px]" />,
         placeholder: "Search",
      },
      {
         icon: <MdGroups color="white" className="h-[20px] w-[20px]" />,
         placeholder: "Groups",
      },
   ];

   return (
      <>
         <div className="hidden md:flex  h-full text-center items-center md:w-[10%] mr-4">
            {preButton}
         </div>
         <div className="video-card-container overflow-hidden ">
            <div className="pt-[2px] absolute z-50 w-full px-3 py-[2px] bg-gradient-to-b from-black bg-opacity-40">
               <div className="w-full text-center pb-[3px]">
                  <p className="text-[#ff3b3b] text-[10px] uppercase font-bold mb-[2px]">
                     CC WATCH VIDEO PAGE /V1 /OCT 11, 2024
                  </p>
               </div>

               <div className="flex justify-between items-center">
                  {HeaderIcons.map((item) => (
                     <div
                        className="flex flex-col items-center pb-[2px] group"
                        onClick={() => handleNavigate(`${item.placeholder}`)}
                     >
                        <div className="cursor-pointer opacity-100 group-hover:scale-110 group-hover:text-blue-500 transition-transform duration-300 ease-in-out">
                           {item.icon}
                        </div>
                        <p className="text-white cursor-pointer font-semibold text-[7px] mt-[1px] group-hover:text-black group-hover:scale-110 transition-transform duration-300 ease-in-out">
                           {item.placeholder}
                        </p>
                     </div>
                  ))}
               </div>
            </div>

            <div className=" h-[85%] md:h-[86%] rounded-none  relative">
               <HomePagePannel
                  AllPosts={AllPosts}
                  preButton={preButton}
                  summary={summary}
                  setLikeLikeEmoji={setLikeLikeEmoji}
                  spilThashTags={spilThashTags}
                  setNextButton={setNextButton}
                  setPreButton={setPreButton}
                  id={id}
               />
            </div>

            <SecretSauceButton />

            <div className="video-card-footer">
               <VideoCardIconList
                  VideoCardHeaderIcons={VideoCardFooterIcons}
                  isfooter={true}
                  openDrawerBottom={openDrawerBottom}
                  handleNavigate={handleNavigate}
               />
            </div>

            <Post
               openBottom={openBottom}
               openDrawerBottom={openDrawerBottom}
               closeDrawerBottom={closeDrawerBottom}
            />

            <ProfileAccountModal
               openBottom={openAccountBottom}
               openDrawerBottom={openDrawerAccountModal}
               closeDrawerBottom={openDrawerAccountModal}
            />
         </div>
         <div className="hidden  md:flex  h-full text-center items-center md:w-[10%] ml-4">

            {nextButton}

         </div>
      </>
   );
}




