import React, { useState } from 'react'

export default function MuteNotificationModal({ setShowMuteNotificationsModal }) {
    const [muteDuration, setMuteDuration] = useState(null);

    return (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-4 rounded-lg shadow-lg max-w-sm w-[80%]">
                <h2 className="text-lg font-bold text-black mb-2">Mute Notifications</h2>
                <p className="text-gray-600 text-[13px] mb-4">
                    How long do you want to mute notifications for this conversation?
                </p>

                {/* Mute Duration Options */}
                <div className="mb-4 grid grid-cols-12">
                    <label className="col-span-12 items-center mb-1">
                        <input
                            type="checkbox"
                            name="muteDuration"
                            value="1 hour"
                            className="form-radio text-blue-500"
                            onChange={(e) => setMuteDuration(e.target.value)}
                        />
                        <span className="ml-2 text-[14px] text-gray-700">1 Hour</span>
                    </label>
                    <label className="col-span-12 items-center mb-1">
                        <input
                            type="checkbox"
                            name="muteDuration"
                            value="8 hours"
                            className="form-radio text-blue-500"
                            onChange={(e) => setMuteDuration(e.target.value)}
                        />
                        <span className="ml-2 text-[14px] text-gray-700">8 Hours</span>
                    </label>
                    <label className="col-span-12 items-center mb-1">
                        <input
                            type="checkbox"
                            name="muteDuration"
                            value="24 hours"
                            className="form-radio text-blue-500"
                            onChange={(e) => setMuteDuration(e.target.value)}
                        />
                        <span className="ml-2 text-[14px] text-gray-700">24 Hours</span>
                    </label>
                    <label className="col-span-12 items-center mb-1">
                        <input
                            type="checkbox"
                            name="muteDuration"
                            value="1 week"
                            className="form-radio text-blue-500"
                            onChange={(e) => setMuteDuration(e.target.value)}
                        />
                        <span className="ml-2 text-[14px] text-gray-700">1 Week</span>
                    </label>
                    <label className="col-span-12 items-center">
                        <input
                            type="checkbox"
                            name="muteDuration"
                            value="forever"
                            className="form-radio text-blue-500"
                            onChange={(e) => setMuteDuration(e.target.value)}
                        />
                        <span className="ml-2 text-[14px] text-gray-700">Until I turn it back on</span>
                    </label>
                </div>
                <div className="flex justify-center gap-4">
                    <button
                        className="rounded-full bg-[#151515] hover:bg-[#272727] text-[12px] font-bold px-10 h-7 text-[#fff]"
                        onClick={() => setShowMuteNotificationsModal(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className="rounded-full text-[12px] px-10 h-7 bg-[#d3d3d3] font-bold hover:bg-[#d3d3d3] text-[#000]"
                        onClick={() => setShowMuteNotificationsModal(false)}
                    >
                        save
                    </button>
                </div>
            </div>
        </div>

    )
}
