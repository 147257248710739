import React, { useState } from "react";
import { IoArrowBackCircle } from "react-icons/io5";
import { LiaSearchSolid } from "react-icons/lia";
import { TbStarHalfFilled } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

export default function OtherUserGroupList() {

  const users = [
    {
      id: 1,
      name: "Ferrari Group",
      dec: "Ferrari Group is an Italian luxury sports car manufacturer, known for high-performance vehicles and success in motorsports, especially Formula 1. Founded by Enzo Ferrari in 1939, it is iconic for its craftsmanship, innovation, and the Prancing Horse logo.",
      isNew: false,
      userImg:
        "https://th.bing.com/th/id/OIP.80o4QiExWaPQKwg0NiZ-_gHaE8?w=234&h=180&c=7&r=0&o=5&dpr=1.2&pid=1.7",
    },
    {
      id: 2,
      name: "Cooking Group",
      dec: "Cooking Group is a community for food enthusiasts to share recipes, cooking techniques, and explore different cuisines.",
      isNew: true,
      userImg:
        "https://th.bing.com/th/id/OIP.823zF-PeNk8Z2l5JSmDosAHaE8?w=271&h=181&c=7&r=0&o=5&pid=1.7",
    },
    {
      id: 3,
      name: "BMW Group",
      dec: "BMW is a German luxury car and motorcycle manufacturer, known for high-performance vehicles and innovative engineering.",
      isNew: false,
      userImg:
        "https://th.bing.com/th/id/OIP.H8hA9khOAPXcvCd_wHlzJgHaEK?w=316&h=180&c=7&r=0&o=5&dpr=1.2&pid=1.7",
    },
    {
      id: 4,
      name: "School Group",
      dec: "A school group is a community of students, teachers, and staff focused on education, teamwork, and personal growth.",
      isNew: true,
      userImg:
        "https://th.bing.com/th/id/OIP.BULrr8cqOb_YWTXoLcgO2gHaEh?w=270&h=180&c=7&r=0&o=5&pid=1.7",
    },
    {
      id: 5,
      name: "Travel Group",
      dec: "Travel Group is a community for adventure seekers who share travel experiences, tips, and recommendations for exploring new destinations.",
      isNew: true,
      userImg:
        "https://th.bing.com/th/id/OIP.8Kwh1XKiAmp6zKaXdpkK1AHaEK?w=315&h=180&c=7&r=0&o=5&pid=1.7",
    },
    {
      id: 6,
      name: "Fitness Group",
      dec: "Fitness Group focuses on promoting health and well-being through exercise routines, nutrition advice, and motivational support.",
      isNew: false,
      userImg:
        "https://th.bing.com/th/id/OIP.1sKJrYW6v8laQs-Nm0GYeAHaE8?w=281&h=187&c=7&r=0&o=5&pid=1.7",
    },
    {
      id: 7,
      name: "Photography Group",
      dec: "Photography Group brings together photography enthusiasts to share tips, techniques, and creative projects.",
      isNew: true,
      userImg:
        "https://th.bing.com/th/id/OIP.Jzki6Vfwl_eoDEX35bBe-gHaFA?w=282&h=191&c=7&r=0&o=5&pid=1.7",
    },
    {
      id: 8,
      name: "Music Group",
      dec: "Music Group is a community of music lovers who explore different genres, share playlists, and discuss the latest in music trends.",
      isNew: false,
      userImg:
        "https://th.bing.com/th/id/OIP.aNsTtPePnGty_Ts8v7DgCgHaE8?rs=1&pid=ImgDetMain",
    }
  ];
  
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');

  const filteredUsers = users.filter(user =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.dec.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <div className="h-full w-full bg-white overflow-y-scroll">
      <div className="flex justify-between items-center w-full h-14 gap-x-4 bg-black sticky top-0 px-4 pt-2">
        <div>
          <IoArrowBackCircle
            className="text-[24px] text-white cursor-pointer"
            onClick={() => navigate(-1)}
          />
        </div>
        <div className="relative w-full">
          <input
            type="text"
            color="blue"
            placeholder="Search....."
            onChange={(e) => setSearchTerm(e.target.value)}
            className="rounded-md h-8 outline-none bg-white px-4 pr-6 placeholder:text-[black] w-full"
          />
          <LiaSearchSolid className="absolute right-2 top-2" />
        </div>
        <p className="absolute top-0 text-red-700 text-center  uppercase text-[10px] w-full font-bold">
          CC / OTHER USER GROUP LIST PAGE / V1 / oct 11 / 2024
        </p>
      </div>
      {filteredUsers.map((data) => (
        <>
          <div
            className="grid grid-cols-12 items-center pt-2 pb-2 px-4 hover:bg-[#d0d0d0b5] text-black cursor-pointer"
            key={data.id}
            onClick={() => navigate("/othergroup")}
          >
            <div className="col-span-2">
              <div className="relative flex items-center justify-center border shadow-[1px_1px_2px_1px_#f687b3]  rounded-md h-8 w-8 p-[1px]">
                <img
                  alt={data.name}
                  src={data.userImg}
                  className="h-7 w-7 rounded-md object-cover"
                />
                {data.isNew && (
                  <p className="blink-text absolute -right-3 -top-2 text-[white] text-[8px] bg-[red] px-[2px] rounded-xl">
                    New
                  </p>
                )}
              </div>
            </div>
            <div className="col-span-8  ml-[-2px]">
              <div className="grid grid-cols-12 w-full">
                <div className="col-span-12 flex justify-between items-center">
                  <div className="flex gap-1 items-center">
                    <p className="text-[14px] font-bold first-letter:uppercase line-clamp-1">{data.name}</p>
                    <p>
                      <TbStarHalfFilled className="text-blue-600 text-[12px]" />
                    </p>
                  </div>
                  <div class="flex -space-x-1 overflow-hidden">
                    <img
                      alt="img"
                      class="inline-block h-5 w-5 rounded-full"
                      src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    />
                    <img
                      alt="img"
                      class="inline-block h-5 w-5 rounded-full"
                      src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    />
                    <img
                      alt="img"
                      class="inline-block h-5 w-5 rounded-full"
                      src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                    />
                    <img
                      alt="img"
                      class="inline-block h-5 w-5 rounded-full invisible group-hover:visible "
                      src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    />
                  </div>
                </div>
                <div className="col-span-12">
                  <p className="text-[10px] line-clamp-1">{data.dec}</p>
                </div>
              </div>
            </div>
            <div className="col-span-2 flex justify-end items-center">
              <div className="bg-[#f86045] text-white rounded-sm text-[12px] p-1 px-3 font-bold">
                Join
              </div>
            </div>
          </div>
          <hr className="w-full mt-[1px] my-[1px] h-[0.5px] bg-gray-700 border-0" />
        </>
      ))}
    </div>
  );
}
