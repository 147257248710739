import React from 'react'

export default function BlockModal({ setShowBlockUserModal }) {
    return (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 overflow-hidden">
            <div className="bg-white p-4 rounded-lg shadow-lg  w-[80%]">
                <h2 className="text-[18px] font-bold text-black mb-2">Block User</h2>
                <p className="text-gray-600 mb-4 text-[14px] ">
                    Are you sure you want to block this user? They will no longer be able to send you messages or view your profile.
                </p>
                <div className="flex justify-center gap-4">
                    <button
                        className="rounded-full bg-[#151515] hover:bg-[#272727] text-[12px] font-bold px-10 h-7 text-[#fff]"
                        onClick={() => setShowBlockUserModal(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className="rounded-full text-[12px] px-10 h-7 bg-[#d3d3d3] font-bold hover:bg-[#d3d3d3] text-[#000]"
                        onClick={() => setShowBlockUserModal(false)}
                    >
                        Block
                    </button>
                </div>
            </div>
        </div>
    )
}
