import React, { Fragment } from "react";
import RecentProducts from "../../Component/shoppage/RecentProducts";
import InputButtonBlack from "../../Component/shoppage/InputButtonBlack";
import MyWishlist from "../../Component/shoppage/MyWishlist";

const WishlistPage = () => {
   return (
      <Fragment>
         <p className="absolute top-1 text-red-700 uppercase font-bold text-center w-full text-[10px]">
            cc / fe / shop whishlist / FE / V3 / 11 OCT, 2024
         </p>
         <MyWishlist />
         <RecentProducts />
         <InputButtonBlack />
      </Fragment>
   );
};

export default WishlistPage;
