import React from "react";
import MyBasket from "../../Component/shoppage/MyBasket";
import InputButtonBlack from "../../Component/shoppage/InputButtonBlack";
import SugestedProductsWhiteBackground from "../../Component/shoppage/SugestedProductsWhiteBackground";

const BasketPage = () => {
   return (
      <div>
         <div className="grid grid-cols-1">
            <p className="absolute top-1 text-red-700 uppercase font-bold text-center w-full text-[10px]">
               cc / fe / shop basketpage / V2 / 11 OCT, 2024
            </p>
            <MyBasket />
            <SugestedProductsWhiteBackground />
            <InputButtonBlack />
         </div>
      </div>
   );
};

export default BasketPage;
