import React, { Fragment } from "react";
import Payment from "../../Component/shoppage/Payment";

const PaymentPage = () => {
   return (
      <Fragment>
         <div className="grid grid-cols-1">
            <p className="absolute top-1 text-red-700 uppercase font-bold text-center w-full text-[10px]">
               cc / fe / shop payment / V2 / 11 OCT, 2024
            </p>
            <Payment />
         </div>
      </Fragment>
   );
};

export default PaymentPage;
