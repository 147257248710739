import {
  FaShareAlt,
  FaRegCopyright,
  FaVideo,
  FaFileVideo,
  FaUserFriends,
  FaSave,
  FaUser,
  FaRegCommentDots,
} from "react-icons/fa";
import { RiAdvertisementLine, RiLiveFill } from "react-icons/ri";
import { MdOutlineDownloading, MdOutlineManageAccounts, MdOutlineSaveAlt } from "react-icons/md";
import { AiFillLike } from "react-icons/ai";
import { BiRepost } from "react-icons/bi";
import { MdDynamicFeed } from "react-icons/md";
import { CiShop } from "react-icons/ci";

import PlayerIcon from "../Assets/HomePageIcons/playerIcon.png";
import FrameIcon from "../Assets/HomePageIcons/Frame 14.png";
import Groupicon from "../Assets/HomePageIcons/Frame 15.png";
import MassageIcon from "../Assets/videoCardIcons/image 24.png";
import MassageIconBlack from "../Assets/videoCardIcons/icons8-messageBlack-50.png";
import Share from "../Assets/videoCardIcons/image 25.png";
import Live from "../Assets/videoCardIcons/image 26.png";
import PostVidoe from "../Assets/videoCardIcons/image 22.png";
import YourCC from "../Assets/videoCardIcons/YourCC.png";
import Homeicon from "../Assets/videoCardIcons/image 20.png";
import ShoppingCard from "../Assets/videoCardIcons/icons8-shopping-basket-64.png";
import Emoji from "../Assets/videoCardIcons/img 24.png";
import YourFriends from "../Assets/videoCardIcons/icons8-friends-64.png";
import YourFriendsBlack from "../Assets/videoCardIcons/icons8-friendsBlack-50.png";
import YourFeed from "../Assets/videoCardIcons/icons8-activity-feed-50.png";
import YourFeedBlack from "../Assets/videoCardIcons/icons8-YourFeed.png";

/* shop list items icons */
import GreenTick from "../Assets/videoCardIcons/greenTick.webp";

import SaveButton from "../Assets/videoCardIcons/icons8-movies-saveButton-64.png";
import Comment from "../Assets/videoCardIcons/icons8-comment-64.png";
import YourAccount from "../Assets/videoCardIcons/icons8-male-user-48.png";
import WhiteGroupIcon from "../Assets/videoCardIcons/WhiteGroupicon.png";
import repostIcon from "../Assets/HomePageIcons/repostIcon.png"

/* video footage */
import video1 from "../videoFootage/pexels-ana-benet-8243253 (720p).mp4";
import video2 from "../videoFootage/pexels-kampus-production-7963107 (720p).mp4";
import video3 from "../videoFootage/pexels-kampus-production-7963239 (720p).mp4";
import video4 from "../videoFootage/production_id_4625520 (720p).mp4";
import video5 from "../videoFootage/production_id_4884244 (720p).mp4";
import video6 from "../videoFootage/production_id_5190120 (720p).mp4";
import video7 from "../videoFootage/production_id_5190121 (720p).mp4";
import video8 from "../videoFootage/video (720p) (1).mp4";

/* Groups icon */
import frame from "../Assets/MessagesIcons/Frame 130.png";
import frame1 from "../Assets/MessagesIcons/Frame 131.png";
import frame2 from "../Assets/MessagesIcons/Frame 132.png";
import frame3 from "../Assets/MessagesIcons/Frame 133.png";
import frame4 from "../Assets/MessagesIcons/Frame 134.png";
import frame5 from "../Assets/MessagesIcons/Frame 135.png";
import viewmembers from "../Assets/GroupsIcons/viewMembers.png";
import exitIcon from "../Assets/GroupsIcons/exitIcon.png";
import muteicon from "../Assets/GroupsIcons/muteicon.png";
import reporticon from "../Assets/GroupsIcons/reporticon.png";
import shareicon from "../Assets/GroupsIcons/shareicon.png";
import groupSettings from "../Assets/GroupsIcons/groupSettings.png";
import Modurator from "../Assets/common/icons8-moderator-64.png";
import ChatRoom from "../Assets/common/chatroom.png";
import Public from "../Assets/common/public.png";
import Sharegroup from "../Assets/videoCardIcons/image 25.png";
import Posted from "../Assets/YourAccountIcons/posted.png";

import { FaCrown } from "react-icons/fa";
import { MdOutlineBrowserUpdated } from "react-icons/md";
import { RiVideoDownloadFill } from "react-icons/ri";
import { HiShare } from "react-icons/hi";
import { TiGroup } from "react-icons/ti";
import { RiUserSettingsFill } from "react-icons/ri";
import { HiMiniUserGroup } from "react-icons/hi2";
import { RiLogoutCircleRLine } from "react-icons/ri";

/* help page images */

import image1 from "../Assets/HelpPage/route 1.svg";
import image2 from "../Assets/HelpPage/image 227.svg";
import image3 from "../Assets/HelpPage/image 226.svg";
import image4 from "../Assets/HelpPage/image 225.svg";
import image5 from "../Assets/HelpPage/image 224.svg";

/* footer icon */

import { IoHeart, IoHeartCircleSharp, IoHome } from "react-icons/io5";
import { RiAccountCircleLine } from "react-icons/ri";
import { SiGooglemessages } from "react-icons/si";
import { TbPhotoVideo } from "react-icons/tb";
import { BsFillCcCircleFill } from "react-icons/bs";
import { IoIosShareAlt } from "react-icons/io";


export const VideoCardFooterIcons = [
  {
    icon: <BsFillCcCircleFill color="white" className="text-[23px]" />,
    placeholder: "Your CC",
  },

  {
    icon: <TbPhotoVideo color="white" className="text-[23px]" />,
    placeholder: "Create video",
  },
  {
    icon: <IoHome color="white" className="text-[23px]" />,
    placeholder: "Home",
  },
  {
    icon: <SiGooglemessages color="white" className="text-[23px]" />,
    placeholder: "Messages",
  },
  {
    icon: <RiAccountCircleLine color="white" className="text-[23px]" />,
    placeholder: "Account",
  },
];



export const VideoCardHeaderIcons = [
  {
    icon: ShoppingCard,
    placeholder: "Shop",
  },
  {
    icon: MassageIcon,
    placeholder: "Messages",
  },
  {
    icon: YourFeed,
    placeholder: "Your Feed",
  },

  {
    icon: YourFriends,
    placeholder: "Your Friends",
  },
  {
    icon: Live,
    placeholder: "Live Stream",
  },
];

export const homeVideoFootage = [
  {
    id: 1,
    footage: video1,
    views: "1.5k",
    img: YourAccount,
    likes: "2.5k",
    name: "Micky",
    time: "2 min",
  },
  {
    id: 2,
    footage: video2,
    views: "6.5k",
    img: YourAccount,
    likes: "9.5k",
    name: "John",
    time: "3 min",
  },
  {
    id: 3,
    footage: video3,
    views: "66k",
    img: YourAccount,
    likes: "27k",
    name: "Albert",
    time: "4 min",
  },
  {
    id: 4,
    footage: video4,
    views: "76k",
    img: YourAccount,
    likes: "97k",
    name: "Merry",
    time: "8 min",
  },
  {
    id: 5,
    footage: video5,
    views: "10k",
    likes: "20k",
    img: YourAccount,
    name: "Roman",
    time: "10 min",
  },
  {
    id: 6,
    footage: video6,
    views: "12k",
    img: YourAccount,
    likes: "19k",
    name: "Rock",
    time: "24 min",
  },
  {
    id: 7,
    footage: video7,
    views: "6k",
    img: YourAccount,
    likes: "7k",
    name: "Warner",
    time: "38 min",
  },
  {
    id: 8,
    footage: video8,
    views: "89k",
    img: YourAccount,
    likes: "56k",
    name: "Virat",
    time: "40 min",
  },
  {
    id: 9,
    footage: video1,
    views: "12k",
    img: YourAccount,
    likes: "7.7k",
    name: "Merry Devis",
    time: "50 min",
  },
];

export const LeftSideBarData = [
  { id: 1, icon: <FaShareAlt />, title: "Share" },
  { id: 2, icon: <FaSave />, title: "Save" },
  { id: 3, icon: <FaUser />, title: "Advertise With us" },
  { id: 4, icon: <RiAdvertisementLine />, title: "Manage Your Ads" },
  { id: 6, icon: <FaRegCopyright />, title: "Your CC" },
  { id: 7, icon: <MdOutlineManageAccounts />, title: "Account" },
  { id: 8, icon: <FaVideo />, title: "Video" },
  { id: 9, icon: <AiFillLike />, title: "Liked" },
  { id: 10, icon: <FaFileVideo />, title: "Post Video" },
  { id: 11, icon: <BiRepost />, title: "Repost" },
  { id: 12, icon: <RiLiveFill />, title: "Live Stream" },
  { id: 13, icon: <FaUserFriends />, title: "Your Friends" },
  { id: 14, icon: <MdDynamicFeed />, title: "YourFeed" },
  { id: 15, icon: <CiShop />, title: "Shop" },
];

export const HeaderlistOfData = [
  {
    img: PlayerIcon,
    title: "PLAYERS",
  },
  {
    img: FrameIcon,
    title: "CXXXXX",
  },
  {
    img: MassageIconBlack,
    title: "MESSAGES",
  },
  {
    img: Groupicon,
    title: "GROUPS",
  },
];

export const ShopCardHeaderIcons = [
  {
    icon: Live,
    placeholder: "Live Stream",
  },
  {
    icon: GreenTick,
    placeholder: "Followed",
  },
  {
    icon: YourFeedBlack,
    placeholder: "Your Feed",
  },

  {
    icon: YourFriendsBlack,
    placeholder: "Your Friends",
  },
  {
    icon: MassageIconBlack,
    placeholder: "Messages",
  },
];



export const videoFootageSideIcon = [
  // {
  //   icon: AccountIcon,
  //   text: "Account",
  // },
  {
    icon: <BiRepost />  /* repostIcon */,

    text: "Repost",
  },
  {
    icon: <MdOutlineSaveAlt /> /* SaveButton */,
    text: "Save",
  },

  // {
  //   icon: Repeat,
  //   text: "Repost",
  // },
  {
    icon: <IoIosShareAlt /> /* Share */,
    text: "Share",
  },
  {
    icon: <FaRegCommentDots />  /*  Comment */,
    text: "Comment",
  },
  {
    icon: <IoHeart /> /* Emoji */,
    text: "Like",
  },
];

export const shopDetails = [
  {
    name: "Recommended",
    listOfData: [
      {
        id: 1,
        icon: "https://img.freepik.com/free-photo/rendering-smart-home-device_23-2151039302.jpg?size=626&ext=jpg&ga=GA1.1.1495381413.1704430097&semt=sph",
        title: "SmartWatch",
        discription:
          "Day and Date Functioning Blue Avatar Leather Strap Quartz Operated Analog Watch - For Men LS2914",
        prise: "$189.00",
      },
      {
        id: 2,
        icon: "https://img.freepik.com/free-photo/black-t-shirt-is-hanging-hanger-with-word-dope-it_1340-38184.jpg?size=626&ext=jpg&ga=GA1.1.1495381413.1704430097&semt=sph",
        title: "T-Shirt",
        prise: "$12.99",
        discription: "Men Striped Round Neck Polyester White, Black T-Shirt",
      },
      {
        id: 3,
        icon: "https://img.freepik.com/free-photo/elegant-smartphone-composition_23-2149437106.jpg?size=626&ext=jpg&ga=GA1.1.1495381413.1704430097&semt=sph",
        title: "Mobile",
        prise: "$999.00",
        discription: "Apple iPhone 15 (Blue, 128 GB)",
      },
    ],
  },
  {
    name: "Popular Items",
    listOfData: [
      {
        id: 1,
        icon: "https://img.freepik.com/free-photo/ice-coffee-with-whipped-cream_144627-3801.jpg?size=626&ext=jpg&ga=GA1.1.1495381413.1704430097&semt=sph",
        title: "Shoe",
        prise: "$35.99",
        discription:
          "Sports Walking Shoes for Men | Soft Cushioned Insole, Slip-Resistance Running Shoes For Men  (White)",
      },
      {
        id: 2,
        icon: "https://img.freepik.com/free-photo/photo-camera-balancing-with-yellow-background_23-2150271772.jpg?size=626&ext=jpg&ga=GA1.1.1495381413.1704430097&semt=sph",
        title: "Camera",
        prise: "$200.00",
        discription:
          "Canon EOS 1500D DSLR Camera Body+ 18-55 mm IS II Lens  (Black)",
      },
      {
        id: 3,
        icon: "https://img.freepik.com/free-photo/headphones-balancing-with-blue-background_23-2150271756.jpg?size=626&ext=jpg&ga=GA1.1.1495381413.1704430097&semt=ais",
        title: "headphone",
        prise: "$10.99",
        discription:
          "boAt Rockerz 551 ANC with Hybrid ANC, 100 HRS Playback, 40mm Drivers & ASAP Charge Bluetooth Headset  (Stellar Black, On the Ear)",
      },
    ],
  },
  {
    name: "Sale Items",
    listOfData: [
      {
        icon: "https://img.freepik.com/free-photo/laptop-balancing-with-purple-background_23-2150271750.jpg?size=626&ext=jpg&ga=GA1.1.1495381413.1704430097&semt=sph",
        title: "Laptop",
        prise: "$300.00",
        discription:
          "HP Laptop Intel Core i3 11th Gen 1115G4 - (8 GB/512 GB SSD/Windows 11 Home) 15s-fq2717TU Thin and Light Laptop  (15.6 Inch, Natural Silver, 1.69 Kg, With MS Office)",
      },
      {
        icon: "https://img.freepik.com/free-photo/television-houseplants-room-scene-generative-ai_188544-12145.jpg?size=626&ext=jpg&ga=GA1.1.1495381413.1704430097&semt=sph",
        title: "Smart Tv",
        prise: "$200.00",
        discription:
          "XElectron Advance 80 cm (32 inch) HD Ready LED Smart Android TV  (32XETV)",
      },
      {
        icon: "https://img.freepik.com/free-photo/front-view-optical-sunglasses-modern-grey-desk-isolated-sight-vision-eyes_140725-18282.jpg?size=626&ext=jpg&ga=GA1.1.1495381413.1704430097&semt=ais",
        title: "Glass",
        prise: "$15.00",
        discription:
          "UV Protection, Riding Glasses, Mirrored Aviator Sunglasses (60)  (For Men & Women, Black)",
      },
    ],
  },
];

export const loginPageDomainName = [
  {
    id: 1,
    name: "Continue with Google",
    Icon: "google",
  },
  {
    id: 2,
    name: "Continue with FB",
    Icon: "facebook",
  },
  {
    id: 3,
    name: "Continue with Snap",
    Icon: "snapchat",
  },
  {
    id: 4,
    name: "Continue with Linked In",
    Icon: "linkedin",
  },
  {
    id: 5,
    name: "Continue with TikTok",
    Icon: "tiktok",
  },
  {
    id: 6,
    name: "Continute with AppleID",
    Icon: "apple",
  },
  {
    id: 7,
    name: "Continue with Cardinals",
    Icon: "",
  },
];

export const similarProducts = [
  {
    id: 1,
    imgUrl: "https://m.media-amazon.com/images/I/81Os1SDWpcL._SX679_.jpg",
    name: "iPhone 15 Pro Max - 256GB",
    description:
      "IPhone 15 Pro Max has a strong and light aerospace-grade titanium design with a textured matte-glass back. It also features a Ceramic Shield front that’s tougher than any smartphone glass. And it’s splash, water, and dust resistant.",
    price: 1791.76,
  },
  {
    id: 2,
    imgUrl: "https://m.media-amazon.com/images/I/71ZP6U9sWTL._SX679_.jpg",
    name: "IPhone 15 Plus",
    description:
      "IPhone 15 Plus features a durable color-infused glass and aluminum design. It’s splash, water, and dust resistant. The Ceramic Shield front is tougher than any smartphone glass. And the 6.7 Super Retina XDR display",
    price: 1094.91,
  },
  {
    id: 3,
    imgUrl: "https://m.media-amazon.com/images/I/71v2jVh6nIL._SX679_.jpg",
    name: "IPhone 15 (256 GB) - Pink",
    description:
      "48MP MAIN CAMERA WITH 2X TELEPHOTO — The 48MP Main camera shoots in super-high resolution. So it’s easier than ever to take standout photos with amazing detail. The 2x optical-quality Telephoto lets you frame the perfect close-up.",
    price: 974.57,
  },
  {
    id: 4,
    imgUrl: "https://m.media-amazon.com/images/I/611mRs-imxL._SX679_.jpg",
    name: "IPhone 14 (256 GB) - (Product) RED",
    description:
      "A15 Bionic chip with 5-core GPU for lightning-fast performance. Superfast 5G cellular",
    price: 794.17,
  },
  {
    id: 5,
    imgUrl: "https://m.media-amazon.com/images/I/71REplb5oZL._SX679_.jpg",
    name: "IPhone 15 (512 GB) - Yellow",
    description:
      "The superfast chip powers advanced features like computational photography, fluid Dynamic Island transitions, and Voice Isolation for phone calls. And A16 Bionic is incredibly efficient to help deliver great all-day battery life.",
    price: 1322.46,
  },
];

export const CreatedGroupMenu = [
  {
    icon: viewmembers,
    text: "Delete Group",
  },
  {
    icon: groupSettings,
    text: "Assign Admin",
  },

  {
    icon: exitIcon,
    text: "Exit Group",
  },
];

export const listOfGroupMenu = [
  {
    icon: viewmembers,
    text: "View Members",
  },
  {
    icon: groupSettings,
    text: "Group setting",
  },

  {
    icon: shareicon,
    text: "Share",
  },
  {
    icon: muteicon,
    text: "Muted",
  },
  {
    icon: reporticon,
    text: "Report",
  },

  {
    icon: exitIcon,
    text: "Exit",
  },
];

export const listofItemsGroup = [
  { id: 1, icon: Modurator, placeholder: "Moderator" },
  { id: 2, placeholder: "Public", icon: Public },
  { id: 3, icon: Posted, placeholder: "Post a Video" },
  { id: 4, placeholder: "Share", icon: Sharegroup },
  { id: 5, placeholder: "Chatroom", icon: ChatRoom },
];

export const groupDataList = [
  { id: 1, icon: frame, text: "Ariel Bradshaw", isFollowingYou: 1 },
  { id: 2, text: "George Rutherford", icon: frame1 },
  { id: 3, icon: frame2, text: "Kate Dawson" },
  { id: 4, text: "Katrine Sharma", icon: frame3 },
  { id: 5, text: "Gigi Cho", icon: frame4 },
  { id: 6, text: "White Tiger’s Rule!", icon: frame5, isFollowingYou: 1 },
];

export const CreatedGroup = [
  {
    id: 1,
    icon: <FaCrown color="white" className="w-[20px] h-[20px]" />,
    placeholder: "Group Info",
  },
  {
    id: 2,
    placeholder: "Updates",
    icon: (
      <MdOutlineBrowserUpdated color="white" className="w-[20px] h-[20px]" />
    ),
  },
  {
    id: 3,
    icon: <RiVideoDownloadFill color="white" className="w-[20px] h-[20px]" />,
    placeholder: "Post a Video",
  },
  {
    id: 4,
    placeholder: "Share",
    icon: <HiShare color="white" className="w-[20px] h-[20px]" />,
  },
  {
    id: 5,
    placeholder: "Chatroom",
    icon: <TiGroup color="white" className="w-[20px] h-[20px]" />,
  },
  {
    id: 6,
    placeholder: "Settings",
    icon: <RiUserSettingsFill color="white" className="w-[20px] h-[20px]" />,
  },
  {
    id: 7,
    placeholder: "Members",
    icon: <HiMiniUserGroup color="white" className="w-[20px] h-[20px]" />,
  },
  {
    id: 8,
    placeholder: "Exit Group",
    icon: <RiLogoutCircleRLine color="white" className="w-[20px] h-[20px]" />,
  },
];

export const CreatedGroupinfoTab = [
  {
    title: "Created By",
    dec: "Mary Davis",
  },
  {
    title: "Created On",
    dec: "June 23,2024",
  },
  {
    title: "Members",
    dec: "10K",
  },
  {
    title: "Posts",
    dec: "15K",
  },
];

export const dummyComment = [
  {
    id: 1,
    message: "Your feet have a direct line to the beat! 👣🎵",
    like: "2.2k",
    dislike: "0.5k",
    name: "Mary paul",
    comment: "1.8k",
    profileurl:
      "https://images.unsplash.com/photo-1485178575877-1a13bf489dfe?q=80&w=2001&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 2,
    message:
      "You dance so well, even my pet rock started tapping its feet! 💃🕺🪨",
    like: "5.8k",
    dislike: "2.5k",
    name: "Stephen paul",
    comment: "3.8k",
    profileurl:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=2080&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 3,
    message:
      "Watching you dance is like witnessing a unicorn prance through a field of rainbows! 🦄🌈",
    like: "10.6k",
    dislike: "2.3k",
    name: "Joseph peter",
    comment: "8.1k",
    profileurl:
      "https://images.unsplash.com/photo-1508214751196-bcfd4ca60f91?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    message: "Your moves are so smooth, they put butter to shame! 🧈💃",
    like: "900k",
    dislike: "30k",
    name: "Matthew steve",
    comment: "549k",
    profileurl:
      "https://images.unsplash.com/photo-1484863137850-59afcfe05386?q=80&w=2071&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 5,
    message:
      "If dancing were an Olympic sport, you'd take home the gold, silver, and bronze! 🏅💃",
    like: "22k",
    dislike: "3.5k",
    name: "John Stephen",
    comment: "10k",
    profileurl:
      "https://images.unsplash.com/photo-1503407768185-30e0f283410a?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 6,
    message: "Your dance moves are hotter than a jalapeño in a sauna! 🔥💃",
    like: "25.5k",
    dislike: "0.7k",
    name: "Neena Simon",
    comment: "9.6k",
    profileurl:
      "https://images.unsplash.com/photo-1500917293891-ef795e70e1f6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 7,
    message:
      "You're like a human disco ball, spreading sparkle and joy with every move! ✨💃",
    like: "89k",
    dislike: "50k",
    name: "Matthew steve",
    comment: "70k",
    profileurl:
      "https://images.unsplash.com/photo-1503830232159-4b417691001e?q=80&w=2109&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 8,
    message:
      "If there were a dance-off between you and gravity, my money would be on you every time! 💸💃",
    like: "1.6M",
    dislike: "0.5M",
    name: "John Stephen",
    comment: "1.1M",
    profileurl:
      "https://images.unsplash.com/photo-1462804993656-fac4ff489837?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 9,
    message: "Your rhythm is contagious! 🎶💃",
    like: "852k",
    dislike: "125k",
    name: "Neena Simon",
    comment: "452k",
    profileurl:
      "https://images.unsplash.com/photo-1601455763557-db1bea8a9a5a?q=80&w=2112&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 10,
    message:
      "Dance like nobody's judging... but if they are, they're definitely jealous! 😏💃",
    like: "9.2k",
    dislike: "6.5k",
    name: "Matthew steve",
    comment: "5.5k",
    profileurl:
      "https://images.unsplash.com/photo-1641894252843-9794796577be?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

export const shareSocialIcons = [
  {
    id: 1,
    iconname: "snapchat",
  },
  {
    id: 2,
    iconname: "instagram",
  },
  // {
  //   id: 3,
  //   iconname: "discord",
  // },
  {
    id: 4,
    iconname: "whatsapp",
  },
  {
    id: 5,
    iconname: "facebook",
  },
  {
    id: 6,
    iconname: "x",
  },
  // {
  //   id: 7,
  //   iconname: "wechat",
  // },
  {
    id: 8,
    iconname: "tiktok",
  },
  // {
  //   id: 9,
  //   iconname: "pinterest",
  // },
  // {
  //   id: 10,
  //   iconname: "patreon",
  // },
  // {
  //   id: 11,
  //   iconname: "slack",
  // },
  {
    id: 12,
    iconname: "github",
  },
  {
    id: 13,
    iconname: "telegram",
  },
  {
    id: 14,
    iconname: "spotify",
  },
  {
    id: 15,
    iconname: "vimeo",
  },
  {
    id: 16,
    iconname: "youtube",
  },
  // {
  //   id: 17,
  //   iconname: "reddit",
  // },
  // {
  //   id: 18,
  //   iconname: "dropbox",
  // },
  // {
  //   id: 19,
  //   iconname: "mailto",
  // },
  {
    id: 20,
    iconname: "",
  },
];

export const shopCategory = [
  {
    id: 1,
    name: "Clothing",
    imgUrl:
      "https://images.unsplash.com/photo-1630329273801-8f629dba0a72?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 2,
    name: "Mobiles",
    imgUrl:
      "https://images.unsplash.com/photo-1585060544812-6b45742d762f?q=80&w=1781&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 3,
    name: "Watches/Gadgets",
    imgUrl:
      "https://images.unsplash.com/photo-1609587312208-cea54be969e7?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    name: "Funiture",
    imgUrl:
      "https://images.unsplash.com/photo-1650013361594-5ea7f37a7378?q=80&w=1926&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 5,
    name: "Laptops",
    imgUrl:
      "https://images.unsplash.com/photo-1552857131-0afaed1953f5?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 6,
    name: "Jewellery",
    imgUrl:
      "https://images.unsplash.com/photo-1605100804763-247f67b3557e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

export const shopPageSlider = [
  {
    id: 1,
    imgUrl:
      "https://images.unsplash.com/photo-1595341888016-a392ef81b7de?q=80&w=1779&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Image 01",
  },
  {
    id: 2,
    imgUrl:
      "https://images.unsplash.com/photo-1465877783223-4eba513e27c6?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Image 02",
  },
  {
    id: 3,
    imgUrl:
      "https://images.unsplash.com/photo-1514989940723-e8e51635b782?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Image 03",
  },
  {
    id: 4,
    imgUrl:
      "https://images.unsplash.com/photo-1520256862855-398228c41684?q=80&w=2069&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Image 04",
  },
  {
    id: 5,
    imgUrl:
      "https://images.unsplash.com/photo-1551107696-a4b0c5a0d9a2?q=80&w=2012&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Image 05",
  },
  {
    id: 6,
    imgUrl:
      "https://images.unsplash.com/photo-1516478177764-9fe5bd7e9717?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    name: "Image 06",
  },
];

export const sugestedProducts = [
  {
    id: 1,
    imgUrl: "https://m.media-amazon.com/images/I/81CgtwSII3L._SX679_.jpg",
    name: "IPhone 15 Pro",
    description:
      "FORGED IN TITANIUM — iPhone 15 Pro has a strong and light aerospace-grade titanium design with a textured matte-glass back. It also features a Ceramic Shield front thats tougher than any smartphone glass. And its splash, water, and dust resistant.",
    price: 1541.55,
  },
  {
    id: 2,
    imgUrl: "https://m.media-amazon.com/images/I/510yXR4GoNL._SX679_.jpg",
    name: "Flat Tawa",
    description: "Aluminium Nonstick Duo Pack Flat Tawa 250 and Fry Pan 200 Gift Set (Red)",
    price: 5.94,
  },
  {
    id: 3,
    imgUrl: "https://m.media-amazon.com/images/I/519qAhI85XL.jpg",
    name: "Soccer Ball",
    description: "Abstract Splatter Art Soccer Ball, 22 cm Diameter, Multicolour",
    price: 59.47,
  },
  {
    id: 4,
    imgUrl: "https://m.media-amazon.com/images/I/61NSE7Q2EwL._SX679_.jpg",
    name: "Titan Analog White Dial Men's Watch",
    description: "EDGE CERAMICS - WHITE",
    price: 129.90,
  },
  {
    id: 5,
    imgUrl: "https://m.media-amazon.com/images/I/51i70GWsOuL._SX300_SY300_QL70_FMwebp_.jpg",
    name: "Monster Trucks",
    description: "GRAPHENE Exciting Pack of 2 Monster Trucks for Boys 3-7 Years Old",
    price: 1.88,
  },
  {
    id: 6,
    imgUrl: "https://m.media-amazon.com/images/I/41SIUVOvusL._SX679_.jpg",
    name: "Air Fryer",
    description: "PHILIPS Air Fryer NA120/00, uses up to 90% less fat, 1500W, 4.2 Liter, with Rapid Air Technology (Black), Large",
    price: 54.48,
  },
  {
    id: 7,
    imgUrl: "https://m.media-amazon.com/images/I/61QVJcrlOrL._SX679_.jpg",
    name: "Adidas Men",
    description: "adidas Men Cotton M LIN SJ T Sports T-Shirts PREBLU,(S)",
    price: 6.89,
  },
];

export const shopDetailsImage = [
  {
    id: 1,
    imgurl: "https://m.media-amazon.com/images/I/81CgtwSII3L._SX679_.jpg",
  },
  {
    id: 2,
    imgurl: "https://m.media-amazon.com/images/I/51nZVUtGqOL._SX679_.jpg",
  },
  {
    id: 3,
    imgurl: "https://m.media-amazon.com/images/I/715zWp1q7rL._SX679_.jpg",
  },
  {
    id: 4,
    imgurl: "https://m.media-amazon.com/images/I/71g7dxYXiOL._SX679_.jpg",
  },
  {
    id: 5,
    imgurl: "https://m.media-amazon.com/images/I/61csXBvcW8L._SX679_.jpg",
  },
];

export const recentProducts = [
  {
    id: 1,
    imgUrl: "https://m.media-amazon.com/images/I/61g4ZnjB5oL._SX679_.jpg",
    name: "Vacuum Cleaner",
    description: "Lifelong 800-Watt Vacuum Cleaner for Home Use with Blower Function, 6 Litre, Wet & Dry, 2.75 Meter Cord, 1.8 Meter Hose Pipe",
    price: 24.97,
  },
  {
    id: 2,
    imgUrl: "https://m.media-amazon.com/images/I/71GeW3HJ3vL._SX679_.jpg",
    name: "Office Chair",
    description: "Merlion Office Chair,High Back Mesh Ergonomic Home Office Desk Chair with 3 Years Warranty",
    price: 67.80,
  },
  {
    id: 3,
    imgUrl: "https://m.media-amazon.com/images/I/71auAIxh1PL._SX679_.jpg",
    name: "Air Tight Containers",
    description: "GOLWYN Air Tight Containers For Kitchen Storage Set, Bpa Container For Kitchen Storage Set",
    price: 9.15,
  },
  {
    id: 4,
    imgUrl: "https://m.media-amazon.com/images/I/51J1uoc+iIL._SY695_.jpg",
    name: "Puma Sneaker",
    description: "Puma Unisex ST Runner v4 L Sneaker",
    price: 30.75,
  },
  {
    id: 5,
    imgUrl: "https://m.media-amazon.com/images/I/8160QjpuoOL._SX679_.jpg",
    name: "Glass Top",
    description: "Elica Vetro Glass Top 4 Burner Auto Ignition Gas Stove",
    price: 74.89,
  },
];

export const wishlistProducts = [
  {
    id: 1,
    imgUrl: "https://m.media-amazon.com/images/I/61F0JO6kw+L._SX679_.jpg",
    name: "4 Seater Sofa",
    description:
      "Modern Design with the traditional moden Chesterfield style in mind, this piece hits all the key elements, with deep button tufting, nailhead accents, scrolled arms and stylish legs.",
    price: 421.57,
    qty: 1,
    manufacture: "Adorn Crafts",
  },
  {
    id: 2,
    imgUrl: "https://m.media-amazon.com/images/I/81gC7frRJyL._SX679_.jpg",
    name: "iPad Pro 11",
    description:
      "iPad Pro is the ultimate iPad experience, with the astonishing performance of the M2 chip, superfast wireless connectivity and next-generation Apple Pencil experience. Plus powerful productivity features in iPadOS.",
    price: 921.45,
    qty: 2,
    manufacture: "Apple",
  },
  {
    id: 3,
    imgUrl: "https://m.media-amazon.com/images/I/611mRs-imxL._SX679_.jpg",
    name: "IPhone 14 (256 GB) - (Product) RED",
    description:
      "A15 Bionic chip with 5-core GPU for lightning-fast performance. Superfast 5G cellular",
    price: 794.17,
    qty: 1,
    manufacture: "Apple",
  },
  {
    id: 4,
    imgUrl: "https://m.media-amazon.com/images/I/61RZDb2mQxL._SX679_.jpg",
    name: "S23 5G - Samsung Galaxy",
    description:
      "More light for your night - Get ready for a Gallery full of epic night shots everyone will want. Nightography's enhanced AI keeps details clear, so low light photos and videos will be bright and colorful from dusk to dawn and back again.",
    price: 782.13,
    qty: 1,
    manufacture: "Samsung",
  },
  {
    id: 5,
    imgUrl: "https://m.media-amazon.com/images/I/71zFyzO8iEL._SL1500_.jpg",
    name: "Titan Analog White Dial Men's Watch",
    description: "EDGE CERAMICS - WHITE",
    price: 481.75,
    qty: 1,
    manufacture: "Titan",
  },
  {
    id: 6,
    imgUrl: "https://m.media-amazon.com/images/I/91pn8t8xiCL._SL1500_.jpg",
    name: "Legendary Whitetails",
    description:
      "Men's Standard Camp Night Berber Lined Hooded Flannel Shirt Jacket, Arrowood Barnwood Plaid, Small",
    price: 293.14,
    qty: 1,
    manufacture: "Puma",
  },
];

export const orderedProducts = [
  {
    id: 1,
    imgUrl: "https://m.media-amazon.com/images/I/61F0JO6kw+L._SX679_.jpg",
    name: "4 Seater Sofa",
    description:
      "Modern Design with the traditional moden Chesterfield style in mind, this piece hits all the key elements, with deep button tufting, nailhead accents, scrolled arms and stylish legs.",
    price: 421.57,
    orderDate: "Apr 17, 2024",
    reviews: "785",
    expectedDeliverDate: "Apr 20, 2024",
    qty: "3",
    manufacturer: "Adorn Crafts",
  },
  {
    id: 2,
    imgUrl: "https://m.media-amazon.com/images/I/81gC7frRJyL._SX679_.jpg",
    name: "iPad Pro 11",
    description:
      "iPad Pro is the ultimate iPad experience, with the astonishing performance of the M2 chip, superfast wireless connectivity and next-generation Apple Pencil experience. Plus powerful productivity features in iPadOS.",
    price: 921.45,
    orderDate: "Apr 20, 2024",
    reviews: "9852",
    expectedDeliverDate: "Apr 28, 2024",
    qty: "1",
    manufacturer: "Apple",
  },
  {
    id: 3,
    imgUrl: "https://m.media-amazon.com/images/I/611mRs-imxL._SX679_.jpg",
    name: "IPhone 14 (256 GB) - (Product) RED",
    description:
      "A15 Bionic chip with 5-core GPU for lightning-fast performance. Superfast 5G cellular",
    price: 794.17,
    orderDate: "Apr 19, 2024",
    reviews: "8522",
    expectedDeliverDate: "Apr 23, 2024",
    qty: "1",
    manufacturer: "Apple",
  },
  {
    id: 4,
    imgUrl: "https://m.media-amazon.com/images/I/61RZDb2mQxL._SX679_.jpg",
    name: "S23 5G - Samsung Galaxy",
    description:
      "More light for your night - Get ready for a Gallery full of epic night shots everyone will want. Nightography's enhanced AI keeps details clear, so low light photos and videos will be bright and colorful from dusk to dawn and back again.",
    price: 782.13,
    orderDate: "Apr 17, 2024",
    reviews: "1258",
    expectedDeliverDate: "Apr 20, 2024",
    qty: "1",
    manufacturer: "Samsung",
  },
  {
    id: 5,
    imgUrl: "https://m.media-amazon.com/images/I/71zFyzO8iEL._SL1500_.jpg",
    name: "Titan Analog White Dial Men's Watch",
    description: "EDGE CERAMICS - WHITE",
    price: 481.75,
    orderDate: "Apr 17, 2024",
    reviews: "985",
    expectedDeliverDate: "Apr 25, 2024",
    qty: "2",
    manufacturer: "Titan",
  },
  {
    id: 6,
    imgUrl: "https://m.media-amazon.com/images/I/91pn8t8xiCL._SL1500_.jpg",
    name: "Legendary Whitetails",
    description:
      "Men's Standard Camp Night Berber Lined Hooded Flannel Shirt Jacket, Arrowood Barnwood Plaid, Small",
    price: 293.14,
    orderDate: "Apr 16, 2024",
    reviews: "8752",
    expectedDeliverDate: "Apr 22, 2024",
    qty: "1",
    manufacturer: "Puma",
  },
];

export const basketProducts = [
  {
    id: 1,
    imgUrl: "https://m.media-amazon.com/images/I/71zj-tRx4mL._SX679_.jpg",
    name: "MSI Gaming Raider GE66",
    description: "12th Generation Intel Core i7-12700H Up To 4.70 GHz",
    price: 4993.56,
    rating: 5,
  },
  {
    id: 2,
    imgUrl: "https://m.media-amazon.com/images/I/71ZP6U9sWTL._SX679_.jpg",
    name: "IPhone 15 Plus",
    description:
      "IPhone 15 Plus features a durable color-infused glass and aluminum design. It’s splash, water, and dust resistant. The Ceramic Shield front is tougher than any smartphone glass. And the 6.7 Super Retina XDR display",
    price: 1094.91,
    rating: 4.5,
  },
  {
    id: 3,
    imgUrl: "https://m.media-amazon.com/images/I/71XwXWxa6QL._SX679_.jpg",
    name: "Galaxy Z Fold5 5G",
    description:
      "Galaxy Z Fold5 does a lot in one hand with its 15.73 cm(6.2-inch) Cover Screen. Unfolded, the 19.21 cm(7.6-inch) Main Screen lets you really get into the zone. Pushed-back bezels and the Under Display Camera means there's more screen and no black dot getting between you and the breathtaking Infinity Flex Display.",
    price: 1985.43,
    rating: 4,
  },
  {
    id: 4,
    imgUrl: "https://m.media-amazon.com/images/I/618d5bS2lUL._SX679_.jpg",
    name: "Apple 2023 MacBook Pro",
    description:
      "The Apple M3 Pro chip, with a 12-core CPU and 18-core GPU using hardware-accelerated ray tracing, delivers amazing performance for demanding workflows like manipulating gigapixel panoramas or compiling millions of lines of code. M3 Max, with an up to 16-core CPU and up to 40-core GPU, drives extreme performance for the most advanced workflows like rendering intricate 3D content or developing transformer models with billions of parameters.",
    price: 3007.04,
    rating: 3.5,
  },
  {
    id: 5,
    imgUrl: "https://m.media-amazon.com/images/I/81Os1SDWpcL._SX679_.jpg",
    name: "iPhone 15 Pro Max - 256GB",
    description:
      "IPhone 15 Pro Max has a strong and light aerospace-grade titanium design with a textured matte-glass back. It also features a Ceramic Shield front that’s tougher than any smartphone glass. And it’s splash, water, and dust resistant.",
    price: 1791.76,
    rating: 3,
  },
  {
    id: 6,
    imgUrl: "https://m.media-amazon.com/images/I/71zFyzO8iEL._SL1500_.jpg",
    name: "Titan Analog White Dial Men's Watch",
    description: "EDGE CERAMICS - WHITE",
    price: 481.75,
    rating: 2.5,
  },
];

export const datalist = [
  { id: 1, icon: frame, text: "Ariel Bradshaw", isFollowingYou: 1 },
  { id: 2, text: "George Rutherford", icon: frame1, isFollowingYou: 1 },
  { id: 3, icon: frame2, text: "Kate Dawson" },
  { id: 4, text: "Katrine Sharma", icon: frame3, isFollowingYou: 1 },
  { id: 5, text: "Gigi Cho", icon: frame4 },
  { id: 6, text: "White Tiger’s Rule!", icon: frame5, isFollowingYou: 1 },
  { id: 7, text: "White Tiger’s Rule!", icon: frame5, isFollowingYou: 1 },
  { id: 8, text: "White Tiger’s Rule!", icon: frame5, isFollowingYou: 1 },
  { id: 9, text: "White Tiger’s Rule!", icon: frame5, isFollowingYou: 1 },
];

export const TrendingVideoData = [
  {
    id: 1,
    video:
      "https://pixabay.com/videos/porsche-vintage-speedster-speedster-28544/",
    dec: "",
  },
  {
    id: 2,
    video: "",
    dec: "",
  },
  {
    id: 3,
    video: "",
    dec: "",
  },
  {
    id: 4,
    video: "",
    dec: "",
  },
  {
    id: 5,
    video: "",
    dec: "",
  },
  {
    id: 6,
    video: "",
    dec: "",
  },
  {
    id: 7,
    video: "",
    dec: "",
  },
  {
    id: 8,
    video: "",
    dec: "",
  },
  {
    id: 9,
    video: "",
    dec: "",
  },
];

export const groupSearchUser = [
  {
    id: 1,
    userName: "@john_doe",
    userImg:
      "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    joined: "2 weeks",
  },
  {
    id: 2,
    userName: "@jane_smith",
    userImg:
      "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    joined: "3 days",
  },
  {
    id: 3,
    userName: "@alexander",
    userImg:
      "https://images.unsplash.com/photo-1542909168-82c3e7fdca5c?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    joined: "1 month",
  },
  {
    id: 4,
    userName: "@emma_watson",
    userImg:
      "https://images.unsplash.com/photo-1521146764736-56c929d59c83?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    joined: "1 year",
  },
  {
    id: 5,
    userName: "@elon_musk",
    userImg:
      "https://images.unsplash.com/flagged/photo-1573740144655-bbb6e88fb18a?q=80&w=1935&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    joined: "6 months",
  },
  {
    id: 6,
    userName: "@marie_curie",
    userImg:
      "https://images.unsplash.com/photo-1551628723-952088378fd3?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    joined: "2 weeks",
  },
  {
    id: 7,
    userName: "@albert_einstein",
    userImg:
      "https://images.unsplash.com/photo-1552294794-060b71bcb8a7?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    joined: "2 days",
  },
  {
    id: 8,
    userName: "@ada_lovelace",
    userImg:
      "https://images.unsplash.com/photo-1543965860-82ed7d542cc4?q=80&w=2060&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    joined: "3 months",
  },
  {
    id: 9,
    userName: "@stephen_hawking",
    userImg:
      "https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    joined: "5 days",
  },
  {
    id: 10,
    userName: "@nikola_tesla",
    userImg:
      "https://images.unsplash.com/photo-1508835277982-1c1b0e205603?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    joined: "1 week",
  },
  {
    id: 11,
    userName: "@nikola_tesla",
    userImg:
      "https://images.unsplash.com/photo-1508835277982-1c1b0e205603?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    joined: "1 week",
  },
  {
    id: 12,
    userName: "@nikola_tesla",
    userImg:
      "https://images.unsplash.com/photo-1508835277982-1c1b0e205603?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    joined: "1 week",
  },
  {
    id: 13,
    userName: "@nikola_tesla nikola_tesla nikola_tesla nikola_tesla",
    userImg:
      "https://images.unsplash.com/photo-1508835277982-1c1b0e205603?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    joined: "1 week",
  },
];

export const groupSearchComment = [
  {
    id: 1,
    dayAgo: "1 week",
    userName: "Mark",
    messages: "Is the wheel popped?",
    profileUrl:
      "https://images.unsplash.com/photo-1639149888905-fb39731f2e6c?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 2,
    dayAgo: "3 days",
    userName: "Jennifer",
    messages: "What time does the event start?",
    profileUrl:
      "https://images.unsplash.com/photo-1546961329-78bef0414d7c?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 3,
    dayAgo: "2 weeks",
    userName: "Chris",
    messages: "I'm bringing snacks for everyone.",
    profileUrl:
      "https://images.unsplash.com/photo-1649123245135-4db6ead931b5?q=80&w=1925&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 4,
    dayAgo: "5 days",
    userName: "Emily",
    messages: "Has anyone seen my phone?",
    profileUrl:
      "https://images.unsplash.com/photo-1553514029-1318c9127859?q=80&w=1964&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 5,
    dayAgo: "4 days",
    userName: "Michael",
    messages: "Let's meet at the usual spot.",
    profileUrl:
      "https://images.unsplash.com/photo-1641894252843-9794796577be?q=80&w=1769&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 6,
    dayAgo: "6 days",
    userName: "Jessica",
    messages: "Who's bringing the drinks?",
    profileUrl:
      "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 7,
    dayAgo: "1 week",
    userName: "Matthew",
    messages: "Don't forget to RSVP for the party.",
    profileUrl:
      "https://images.unsplash.com/photo-1521119989659-a83eee488004?q=80&w=1923&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 8,
    dayAgo: "2 days",
    userName: "Sarah",
    messages: "I'll be there early to set up.",
    profileUrl:
      "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 9,
    dayAgo: "3 weeks",
    userName: "Jason",
    messages: "Is anyone else carpooling?",
    profileUrl:
      "https://plus.unsplash.com/premium_photo-1663054609755-5be53e6d599a?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
  {
    id: 10,
    dayAgo: "4 weeks",
    userName: "Amanda",
    messages: "Looking forward to seeing everyone!",
    profileUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  },
];

export const groupSearchMedia = [
  {
    id: 1,
    mediaMsg: "Check out these awesome wheels!",
    mediaImg:
      "https://images.unsplash.com/photo-1542377281-73d08e3a10aa?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userImg:
      "https://images.unsplash.com/photo-1633332755192-727a05c4013d?q=80&w=1780&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userName: "Tyler Johnson",
  },
  {
    id: 2,
    mediaMsg: "Can't wait to get these new wheels on my ride!",
    mediaImg:
      "https://images.unsplash.com/photo-1542362566-2cf4348e2167?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userImg:
      "https://images.unsplash.com/photo-1568602471122-7832951cc4c5?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userName: "Samantha Smith",
  },
  {
    id: 3,
    mediaMsg: "Just got my wheels polished! What do you think?",
    mediaImg:
      "https://images.unsplash.com/photo-1552962730-61f41ae62092?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userImg:
      "https://images.unsplash.com/photo-1557862921-37829c790f19?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userName: "Brandon Williams",
  },
  {
    id: 4,
    mediaMsg: "Wheels are the finishing touch to any car!",
    mediaImg:
      "https://images.unsplash.com/photo-1544986342-f4f2e11b7c02?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userImg:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userName: "Nicole Davis",
  },
  {
    id: 5,
    mediaMsg: "New wheels, new adventures!",
    mediaImg:
      "https://images.unsplash.com/photo-1558981396-5fcf84bdf14d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userImg:
      "https://images.unsplash.com/photo-1506794778202-cad84cf45f1d?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userName: "Ryan Martinez",
  },
  {
    id: 6,
    mediaMsg: "Nothing like the shine of freshly cleaned wheels!",
    mediaImg:
      "https://images.unsplash.com/photo-1558980394-4c7c9299fe96?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userImg:
      "https://images.unsplash.com/photo-1529626455594-4ff0802cfb7e?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userName: "Emily Taylor",
  },
  {
    id: 7,
    mediaMsg: "Just installed some sick rims!",
    mediaImg:
      "https://images.unsplash.com/photo-1573950940509-d924ee3fd345?q=80&w=1846&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userImg:
      "https://images.unsplash.com/photo-1534030347209-467a5b0ad3e6?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userName: "Jacob Brown",
  },
  {
    id: 8,
    mediaMsg: "Wheels can completely change the look of a car!",
    mediaImg:
      "https://images.unsplash.com/photo-1570280407069-9017ba84a3a2?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userImg:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userName: "Jessica Miller",
  },
  {
    id: 9,
    mediaMsg: "Check out these sweet rims!",
    mediaImg:
      "https://images.unsplash.com/photo-1513036191774-b2badb8fcb76?q=80&w=1888&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userImg:
      "https://images.unsplash.com/photo-1619895862022-09114b41f16f?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userName: "Amanda Moore",
  },
  {
    id: 10,
    mediaMsg: "These wheels are perfect for off-roading!",
    mediaImg:
      "https://images.unsplash.com/photo-1578148211838-491f3014dfc8?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userImg:
      "https://images.unsplash.com/photo-1487222477894-8943e31ef7b2?q=80&w=1995&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userName: "Madison King",
  },
  {
    id: 10,
    mediaMsg: "These wheels are perfect for off-roading!",
    mediaImg:
      "https://images.unsplash.com/photo-1578148211838-491f3014dfc8?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userImg:
      "https://images.unsplash.com/photo-1487222477894-8943e31ef7b2?q=80&w=1995&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userName: "Madison King",
  },
  {
    id: 10,
    mediaMsg: "These wheels are perfect for off-roading!",
    mediaImg:
      "https://images.unsplash.com/photo-1578148211838-491f3014dfc8?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userImg:
      "https://images.unsplash.com/photo-1487222477894-8943e31ef7b2?q=80&w=1995&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userName: "Madison King",
  },
  {
    id: 10,
    mediaMsg: "These wheels are perfect for off-roading!",
    mediaImg:
      "https://images.unsplash.com/photo-1578148211838-491f3014dfc8?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userImg:
      "https://images.unsplash.com/photo-1487222477894-8943e31ef7b2?q=80&w=1995&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    userName: "Madison King",
  },
];

export const helpPageData = [
  {
    id: 1,
    title: "Get Started",
    img: image1,
    data: [
      {
        Id: 1,
        qus: "How to create an account?",
        ans: "To create an account, click on the sign-up button and fill in the required details. Once you have filled in the details, click on the sign-up button. You will receive a verification email. Click on the link in the email to verify your account.",
      },
      {
        id: 2,
        qus: "My email address is not recognized when I try to reset my password? ",
        ans: "If your email address is not recognized when trying to send the password reset email, it’s possible you may have used a different email address to register your account.. ",
      },
      {
        id: 7,
        qus: "Why can’t i access my email address and i can’t log in ? ",
        ans: "If your email address is not recognized when trying to send the password reset email, it’s possible you may have used a different email address to register your account.. ",
      },
      {
        id: 8,
        qus: "Why my account was permanently supended ? ",
        ans: "If your email address is not recognized when trying to send the password reset email, it’s possible you may have used a different email address to register your account.. ",
      },
      {
        id: 9,
        qus: "How to Reopen an account you closed ? ",
        ans: "If your email address is not recognized when trying to send the password reset email, it’s possible you may have used a different email address to register your account.. ",
      },
      {
        id: 10,
        qus: "What are log in alert notifications ? And when are they sent ?",
        ans: "If your email address is not recognized when trying to send the password reset email, it’s possible you may have used a different email address to register your account.. ",
      },
    ],
  },
  {
    id: 2,
    title: "Manage account",
    img: image5,
    data: [
      {
        qus: "How to create an account?",
        id: 3,
        ans: "To create an account, click on the sign-up button and fill in the required details. Once you have filled in the details, click on the sign-up button. You will receive a verification email. Click on the link in the email to verify your account.",
      },
    ],
  },
  {
    id: 3,
    title: "Account Privacy",
    img: image4,
    data: [
      {
        qus: "How to create an account?",
        id: 4,
        ans: "To create an account, click on the sign-up button and fill in the required details. Once you have filled in the details, click on the sign-up button. You will receive a verification email. Click on the link in the email to verify your account.",
      },
    ],
  },
  {
    id: 4,
    title: "Policies & Reporting",
    img: image3,

    data: [
      {
        qus: "How to create an account?",
        id: 5,
        ans: "To create an account, click on the sign-up button and fill in the required details. Once you have filled in the details, click on the sign-up button. You will receive a verification email. Click on the link in the email to verify your account.",
      },
    ],
  },
  {
    id: 5,
    title: "Advertisement",
    img: image2,

    data: [
      {
        qus: "How to create an account?",
        id: 6,
        ans: "To create an account, click on the sign-up button and fill in the required details. Once you have filled in the details, click on the sign-up button. You will receive a verification email. Click on the link in the email to verify your account.",
      },
    ],
  },
];
