import React, { useEffect, useRef, useState } from "react";
import { Avatar } from "@material-tailwind/react";
import { FaRegEye, FaSmile } from "react-icons/fa";
import { TbDotsVertical } from "react-icons/tb";
import { HiMiniSpeakerWave, HiMiniSpeakerXMark } from "react-icons/hi2";
import { useOutsideClick } from "../Hooks/useOutsideClick";

export default function VideoCard({
  id,
  views,
  likes_count,
  body,
  video,
  avatar_url,
  handleDeletePost,
  threeDots,
  belowDecs,
  selectVideo,
}) {
  const menuRef = useRef(null);
  const [postId, setpostId] = useState(null);
  const [sound, setSound] = useState(true);
  const [hovered, setHovered] = useState(false);

  const videoRef = useRef();

  const handleMouseEnter = () => {
    const playPromise = videoRef.current.play();
    if (playPromise !== undefined) {
      playPromise.catch((error) => {
        // Auto-play was prevented
        // Show a UI element to let the user manually start playback
      });
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      videoRef.current.pause();
    }
  };

  const handleClickOutside = () => {
    setpostId(null);
  };
  useOutsideClick(menuRef, handleClickOutside);

  return (
    <div
      className={`col-span-1 ${
        !belowDecs ? "flex flex-col-reverse gap-1" : ""
      }`}
      key={id}
    >
      <div
        className="h-[27vh] w-[100%] relative rounded-md overflow-hidden"
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <div
          className="w-full h-full rounded-md"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <video
            autoPlay={hovered}
            ref={videoRef}
            src={`${video}`}
            className="h-full w-full object-cover rounded-md cursor-pointer"
            controlsList="nodownload noremoteplayback"
            muted={sound}
            onClick={() => selectVideo(id)}
          />
          {video && threeDots && (
            <TbDotsVertical
              className="absolute right-0 top-2 font-bold cursor-pointer z-50 w-6 h-86"
              color="white"
              onClick={() => setpostId(id)}
            />
          )}
          {postId === id && (
            <div
              ref={menuRef}
              className="absolute text-[#000] font-bold bg-[#D9D9D9] px-2 py-1  text-[15px] right-2 top-6 z-50"
            >
              <p
                className="flex items-center text-[10px] p-1 cursor-pointer"
                onClick={() => handleDeletePost(id)}
              >
                Delete
              </p>
            </div>
          )}
        </div>
        <div className="flex justify-between absolute bottom-0 w-full overflow-hidden">
          <div className="rounded-sm w-[45%] flex justify-center gap-2 items-center bg-[#191919] opacity-80 hover:opacity-[10]">
            <FaRegEye className="text-[1rem] text-[#ffffff]" />
            <span className="text-[#ffffff] text-[13px]">{12}</span>
          </div>
          <div className="rounded-sm w-[45%] flex justify-center gap-2 items-center bg-[#191919] opacity-80 hover:opacity-[10]">
            <FaSmile className="text-[1rem] text-yellow-700" />
            <span className="text-[#ffffff] text-[13px]">{likes_count}</span>
          </div>
        </div>
        <div className="flex justify-between absolute top-2 left-0 w-full px-2">
          <div
            className={`rounded-full p-[2px] flex justify-center items-center ${
              hovered ? "bg-[#ffffff]" : ""
            } opacity-75 hover:opacity-90`}
          >
            {!sound ? (
              <HiMiniSpeakerWave
                className="text-[15px]"
                onClick={() => setSound(!sound)}
              />
            ) : (
              <HiMiniSpeakerXMark
                className="text-[15px]"
                onClick={() => setSound(!sound)}
              />
            )}
          </div>
        </div>
      </div>
      {body && (
        <div className="grid grid-cols-12 items-center mt-1">
          <Avatar src={`${avatar_url}`} className="h-5 w-5 col-span-2" />
          <p className="font-medium text-[8px] col-span-10 text-[#fff] line-clamp-1 ml-1">
            {body}
          </p>
        </div>
      )}
    </div>
  );
}
